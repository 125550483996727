import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {MatDialog} from '@angular/material';
import {tap} from 'rxjs/operators';
import {AuthService} from '../../../../services/auth.service';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(
    private dialog: MatDialog,
    private _as: AuthService,
    private router: Router
) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let changedReq;
  if (req.url.match('getCountries') ||
    req.url.match('login') ||
    req.url.match('googleapis') ||
    req.url.match('register') ||
    req.url.match('forgotPassword') ||
    req.url.match('emailVerification') ||
    req.url.match('emailResend') ||
    req.url.match('verifyOTP') ||
    req.url.match('resetPassword') ||
    req.url.match('query')
  ) {
    changedReq = req;
  } else {
    const headers = this._as.getHeaders();
    changedReq = req.clone(headers);
  }
  return next.handle(changedReq).pipe(
    tap(
      event => {
        if (event instanceof HttpResponse) {
          if (this._as.canCheckAuth(event) && event.body) {
            if (
              (
                typeof event.body.error !== 'undefined' && event.body.error === true &&
                ( event.body.message === 'token_expired'
                  || event.body.message === 'token_not_provided'
                  || event.body.message === 'token_invalid'
                  || event.body.message === 'Invalid Token!'
                  || event.body.message === 'Token not Provided'
                  || event.body.message === 'Unauthorised!' 
                  // *****************************  Added the below methods to logout the user and navigate to the Login page in case of api error(logout message) (By Pankaj Phour) *****************************
                  || event.body.message === 'Sorry, You have been logged out.'
                  || event.body.code === 440 
                  || event.body.code === 401 
                )
              )
            ) {
              // console.log('forcelogout due to inactivity');
              this._as.forceLogout(event.body.message);
            } else if (
              !event.url.match(/isValidToken/g)
              && !event.url.match(/login/g)
              && !event.url.match(/logout/g)
              && !event.url.match(/forgotPassword/g)
              && !event.url.match(/resetPassword/g)
              && !event.url.match(/getCountries/g)
              && !event.url.match(/emailVerification/g)
              && !event.url.match(/emailResend/g)
              && !event.url.match(/verifyOTP/g)
              && !event.url.match(/register/g)) {
              // this._as.refreshToken();
            }
          }
        }
        return event;
      },
      err => {
        if (err.status === 401 || err.code === 401) {
          this._as.obNotify({status: 'error', message: 'Unauthorized. Please login and try again', start: true, code: err.status});
          localStorage.clear();
          this.router.navigate(['auth/login']);
        }
        if (err.status === 500) {
          this._as.obNotify({status: 'error', message: err.statusText, start: true, code: err.status});
        }
        if (err.status === 404 || err.status === 422) {
          this._as.obNotify({status: 'error', message: err.error.message, start: true, code: err.status});
        }
        if (err.status === 400) {
          this._as.obNotify({status: 'error', message: 'Unauthorized. Please login and try again', start: true, code: err.status});
          localStorage.clear();
          this.router.navigate(['auth/login']);
        }
      }
    ));
}
}
