import { ElementRef, Injectable, EventEmitter, Output } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { catchError, debounce, last, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';

export interface TabList {
  name: string;
  value: string;
  disabled: boolean;
  locked: boolean;
  url: string;
  image: string;
  style: {
    height: string;
    width: string;
    'margin-right': string;
  };
  visible: boolean;
  completed: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public infoList = [
    { questionType: 'single', name: 'Single Choice', desc: 'Questions where user can choose only one option', image: 'single.svg', icon: 'radio_button_checked' },
    { questionType: 'multiple', name: 'Multiple Choice', desc: 'Questions where user can choose more than one option', image: 'multiple.svg', icon: 'check_box' },
    { questionType: 'numeric', name: 'Likert Scale', desc: 'Numeric question gives you an option to rate within a range', image: 'numeric.svg', icon: 'looks_3' },
    { questionType: 'freetext', name: 'Free Text', desc: 'Question for the users, where users are free to write their opinion as text', image: 'freetext.svg', icon: 'text_format' },
    { questionType: 'grid', name: 'Grid', desc: 'You can add grid question', image: 'image.svg', icon: 'grid_on' },
    { questionType: 'image', name: 'Image', desc: 'You can give images as options of a question', image: 'image.svg', icon: 'photo' },
  ];
  public tabs: TabList[] = [
    { name: 'Upload Content', value: 'AddContent', disabled: true, locked: false, url: '/corporate/createCam', image: '/assets/icons/addcontent/addcontent.svg', style: { height: '20.66px', width: '15.5px', 'margin-right': '10px' }, visible: true, completed: false },
    { name: 'Configure', value: 'SelectAudience', disabled: true, locked: false, url: '/corporate/configure', image: '/assets/icons/addcontent/drag.svg', style: { height: '23.13px', width: '15.5px', 'margin-right': '10px' }, visible: true, completed: false },
    { name: 'Configure Pre-Survey', value: 'ConfigurePreSurvey', disabled: true, locked: false, url: '/corporate/preSurvey', image: '/assets/icons/addcontent/settings.svg', style: { height: '23.13px', width: '15.5px', 'margin-right': '10px' }, visible: true, completed: false },
    { name: 'Configure In-Stream Survey', value: 'ConfigureInStream', disabled: true, locked: false, url: '/corporate/inStream', image: '/assets/icons/addcontent/settings.svg', style: { height: '23.13px', width: '15.5px', 'margin-right': '10px' }, visible: true, completed: false },
    { name: 'Configure Post-Survey', value: 'ConfigurePostSurvey', disabled: true, locked: false, url: '/corporate/postSurvey', image: '/assets/icons/addcontent/camera-settings-cogwheel.svg', style: { height: '21.75px', width: '15.5px', 'margin-right': '10px' }, visible: true, completed: false },
    { name: 'Review', value: 'Review', disabled: true, locked: false, url: '/corporate/review-single', image: '/assets/icons/addcontent/review.svg', style: { height: '19.85px', width: '15.5px', 'margin-right': '10px' }, visible: true, completed: false },
  ];
  public tabSubject: BehaviorSubject<TabList[]> = new BehaviorSubject<TabList[]>(this.tabs);

  public pageStageSubject: BehaviorSubject<number> = new BehaviorSubject<number>(1);

  public tutorialElements = [];
  public category = [];
  public showTutorial = true;
  public longContentSetting: any;
  public tutorial = false;
  public preSurvey: ElementRef;
  public syncTime = new BehaviorSubject(0);
  env = environment;

  constructor(private http: HttpClient) {
    if (localStorage.getItem('api_token')) {
      this.userGet('getLongcontentSettings').subscribe(success => {
        if (success && success.response && success.response.length) {
          success['response'].filter(f => {
            if (f.lcnts_type === 'all') {
              // console.log(f, 'aall');
              this.longContentSetting = f;
            }
          });
          this.longContentSetting['all_settings'] = success.response;
          if (localStorage.getItem('cnt_data')) {
            this.setLongFormSetting();
          }
        }
      });
    }
  }

  public getQuestionDetail(type: string): any {
    type = type.toLowerCase() || '';
    return this.infoList.find(d => d.questionType === type);
  }

  campaignSelectedEvent = new EventEmitter();

  campaignSelected(data: any) {
    this.campaignSelectedEvent.emit(data);
  }

  // Declaring EventEmitters here 

  // This is the eventEmitter declared to get value when cohorts data is modified from the filter-bar component (by Pankaj Phour) on March 24 2023 
  @Output() editedOptionEvent = new EventEmitter();
  editedOption(data: any) {
    this.editedOptionEvent.emit(data)
  }

  // This is the eventEmitter declared to get value when cohorts data is modified from the filter-bar component (by Pankaj Phour) on March 24 2023 
  @Output() optionFormatterEvent = new EventEmitter();
  optionFormatter(data: any) {
    this.optionFormatterEvent.emit(data)
  }

  // This is the eventEmitter declared to get value when cohorts data is modified from the filter-bar component (by Pankaj Phour) on March 24 2023 
  @Output() cohortEmitter = new EventEmitter();
  cohortModified(data: any) {
    this.cohortEmitter.emit(data)
  }

  // This is the eventEmitter for the data points filtering from head-toolbar component (coded by Pankaj Phour) on March 24 2023 
  @Output() dataPointEmitter = new EventEmitter();
  dataPointModified(data: any) {
    this.dataPointEmitter.emit(data)
  }


  // This is the eventEmitter for sending the graph type changing events eg.-> from line to bar or radio (coded by Pankaj Phour) on March 24 2023 
  @Output() tabChangeEmitter = new EventEmitter();
  tabChanged(data: any) {
    this.tabChangeEmitter.emit(data)
  }

  // This is the eventEmitter for sending the reaction emotion changing events eg.-> from reaction to emotion (coded by Pankaj Phour) on April 06 2023
  @Output() selectionChangeEmitter = new EventEmitter();
  selectionChange(data: any) {
    this.selectionChangeEmitter.emit(data)
  }

  // This is the eventEmitter for sending the hook tab clicked data to line graph  to zoom graph  upto 3 seconds (coded by Chandra kant Gautam) on May 26 2023
  @Output() hookTabClicked = new EventEmitter();
  hookTabChange(data: any) {
    this.hookTabClicked.emit(data)
  }


  // This is the eventEmitter for sending the selected campaign to clone data from clone page to top component (by Pankaj Phour) on May 26 2023
  @Output() campaignSelectedforCloneEvent = new EventEmitter();
  campaignSelectedforClone(data: any) {
    this.campaignSelectedforCloneEvent.emit(data)
  }

  private _progressMessage = new BehaviorSubject<any>('');
  _progressMessage$ = this._progressMessage.asObservable();

  private _progress = new BehaviorSubject<any>(0);
  progress$ = this._progress.asObservable();

  private _activePlan = new BehaviorSubject<any>('');
  activePlan$ = this._activePlan.asObservable();

  private _cancelRequest = new BehaviorSubject<any>('');
  _cancelRequest$ = this._cancelRequest.asObservable();

  private _user = new BehaviorSubject<any>({ status: 'success', message: 'message', start: false, code: 200 });
  user$ = this._user.asObservable();

  private _notify = new BehaviorSubject<any>({ status: 'success', message: 'message', start: false, code: 200 });
  notify$ = this._notify.asObservable();

  private _staticData = new BehaviorSubject<any>('');
  _staticData$ = this._staticData.asObservable();

  public Positive = new BehaviorSubject<any>('0');
  Positive$ = this.Positive.asObservable();

  public Support = new BehaviorSubject<any>('0');
  Support$ = this.Support.asObservable();

  private _sortBy = new BehaviorSubject(<any>(''));
  _By$ = this._sortBy.asObservable();

  private _animateSlider = new BehaviorSubject<any>('');
  _animateSlider$ = this._animateSlider.asObservable();

  private _isCluster = new BehaviorSubject<any>('');
  _isCluster$ = this._isCluster.asObservable();

  private _playSyn = new BehaviorSubject<any>('');
  _playSyn$ = this._playSyn.asObservable();

  public campaignType = new BehaviorSubject<any>('');
  campaignType$ = this.campaignType.asObservable()


  static mapItems(items) {
    // console.log('slkegh', items);
    const selectedItems = items.filter((l) => {
      // console.log(l.status);
      return l.status;
    }).map((l) => l.key);
    // console.log('slkegh', selectedItems);
    return selectedItems.length ? selectedItems : null;
  }
  tabCheck(clutter, instream) {
    /*if(clutter) {
      return this.tabs.map( (m: any) => {
        if(m.value === 'Review') {
          m.url = '/corporate/review-cluster';
        }
        if(m.value !== 'ConfigureInStream') {
          return m;
        }
      }).filter(n =>  n);
    }
    return this.tabs;*/
    // console.log(clutter,instream);

    const tabs = this.tabs.map(m => {
      if (clutter) {
        if (m.value === 'Review') {
          m.url = '/corporate/review-cluster';
        }
        if (m.value !== 'ConfigureInStream') {
          m.locked = true;
        }
        return m;
      } else {
        if (instream === 'SVF') {
          if (m.value === 'ConfigureInStream' || m.value === 'SelectAudience') {
            m.locked = true;
          }
        }
        else {
          if (m.value === 'ConfigureInStream') {
            m.locked = !instream;
          }
        }
        return m;
      }
    }).filter(m => !m.locked);
    return tabs;
  }
  obIsCluster(data: any): void {
    this._isCluster.next(data);
  }
  obplaySyn(data: any): void {
    this._playSyn.next(data);
  }
  obProgressMessage(data: any): void {
    this._progressMessage.next(data);
  }
  obProgress(data: any): void {
    this._progress.next(data);
  }
  obActivePlan(data: any): void {
    this._activePlan.next(data);
  }

  obCancelRequest(data: any): void {
    this._cancelRequest.next(data);
  }
  obStaticData(data: any): void {
    this._staticData.next(data);
  }
  private handleError(file) {
    return file;
  }

  obNotify(data: any): void {
    this._notify.next(data);
  }

  obUser(data: any): void {
    this._user.next(data);
  }
  obSoryby(data: any): void {
    this._sortBy.next(data);
  }

  setLongFormSetting(data: any = {}) {
    if (!data.cnt_length) {
      data = JSON.parse(localStorage.getItem('cnt_data'));
    }
    this.tabs.map(m => {
      if (m.value === 'ConfigureInStream') {
        m['visible'] = +data.cnt_length > this.longContentSetting.lcnts_length;
      }
      return m;
    });
  }
  /* FORKJOIN */
  public getAssets(): Observable<any[]> {
    const response1 = this.http.post(environment.apiUrl + 'getList', { page_stage_range: [1, 12] });
    const response2 = this.http.get(environment.apiUrl + 'unmappedContents');
    // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
    return forkJoin([response1, response2]);
  }

  /** Return distinct message for sent, upload progress, & response events */
  private getEventMessage(event: HttpEvent<any>, file: any, index) {
    if (event['body'] && !event['body'].error) {
      this.obProgressMessage({ cmp_id: event['body'].response.cnt_id, index: index });
    }
    switch (event.type) {
      case HttpEventType.Sent:
        return `Adding asset to your campaign`;

      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        return { progress: Math.round(100 * event.loaded / event.total), message: 'Asset Uploading...', index: index };

      case HttpEventType.Response:
        return event.body;

      default:
        return `Wait for a while till your asset being finalized`;
    }
  }
  private showProgress(message, index) {
    if (message && message.progress && message.progress < 100) {
      this.obProgress({ message: message.progress, index: index });
      this.obProgressMessage({ message: message.message, index: index });
    } else if (message && message.progress && message.progress === 100) {
      this.obProgressMessage({ message: 'Campaign assets uploaded', index: index });
    } else {
      this.obProgressMessage({ message: message, index: index });
    }
  }
  public userGet(endpoint) {
    return this.http.get<any>(environment.apiUrl + endpoint);
  }

  public userGetYoutube(endpoint) {
    return this.http.get<any>(endpoint);
  }
  public reportGet(endpoint) {
    return this.http.get(environment.apiUrlIP + endpoint);
  }
  public userPost(endpoint, params) {
    return this.http.post<any>(environment.apiUrl + endpoint, params);
  }
  public userPut(endpoint, params) {
    return this.http.put(environment.apiUrl + endpoint, params);
  }
  public userDelete(endpoint) {
    return this.http.delete(environment.apiUrl + endpoint);
  }
  public deleteData(endpoint) {
    return this.http.delete(environment.apiUrl + endpoint);
  }

  public skipClone(endpoint: any, params: any) {
    return this.http.post(environment.apiUrl + endpoint, params)
  }
  addTutorial(elements): void {
    if (this.showTutorial) {
      this.tutorialElements = [];
      this.tutorial = true;
      this.tutorialElements = elements;
    }
  }
  /*  public prograssFun(userData, index) {
      const req = new HttpRequest('POST',  userData, {
        reportProgress: true
      });
      const res = this.http.request(req).pipe(
        map(event => this.getEventMessage(event, userData, index)),
        tap(message => this.showProgress(message, index)),
        last(), // return last (completed) message to caller
        catchError(this.handleError(userData))
      );
      return res;
    }*/
  public uploadContent(userData, endpoint, index) {
    const req = new HttpRequest('POST', environment.apiUrl + endpoint, userData, {
      reportProgress: true
    });
    const res = this.http.request(req).pipe(
      map(event => this.getEventMessage(event, userData, index)),
      tap(message => this.showProgress(message, index)),
      last(), // return last (completed) message to caller
      catchError(this.handleError(userData))
    );
    return res;
  }
  public randomString(length): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  public makeLink(content, link_type, extra): string {
    // console.log("content for making url", content)
    let comp_id = JSON.parse(localStorage.getItem('comp_id'));
    // console.log(comp_id);
    let link = 'https://';

    // if(comp_id===1383){
    //   link += 'dev.monetrewards.com/walmartuser';
    //   if (content.quota_type === 3 || content.quota_type === 2) {
    //     link += '/' + this.env.watchLink.build;
    //     link += (link_type === 'content' ? '?a=' : '?c=') + (content.cnt_id).toString(16);
    //   } else {
    //     link += '/' + this.env.watchLink.base;
    //     Object.keys(extra).map((m, i) => {
    //       if (i === 0) {
    //         link += '?' + m + '=' + extra[m];
    //       } else {
    //         link += '&' + m + '=' + extra[m];
    //       }
    //     });
    //   }
    // }
    // else{
    link += this.env.watchLink.server;
    // link += content.cmp_type == '1' ? '/svf' : '/diy'
    if (content.quota_type === 3 || content.quota_type === 2) {

      // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ Removed the extra slash from the url of short link ( by Pankaj Phour) on january 18 2023 ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
      // link += '/' + this.env.watchLink.build;
      // link += this.env.watchLink.build;
      link += (content.cmp_type == '1' ? this.env.watchLink.moduleSvf : this.env.watchLink.moduleDiy);
      link += (link_type === 'content' ? '?a=' : '?c=') + (content.cnt_id).toString(16);

    } else {
      // ****************************************  Changed the watchlink url to Modified frontend for Combined SVf and DIy user side ( By Pankaj Phour) ****************************************
      // link += '/' + this.env.watchLink.base;
      link += (content.cmp_type == '1' ? this.env.watchLink.moduleSvf : this.env.watchLink.moduleDiy) + this.env.watchLink.base;
      Object.keys(extra).map((m, i) => {
        if (i === 0) {
          link += '?' + m + '=' + extra[m];
        } else {
          link += '&' + m + '=' + extra[m];
        }
      });
    }
    // }

    /*console.log(link);*/
    return link;
  }


  bindCheckBoxGroup(array) {
    // console.log('array', array)
    const group = [];
    const group1 = [];
    array.map((l) => {
      group1.push(new FormGroup({
        key: new FormControl(l.key),
        value: new FormControl(l.name.toLowerCase()),
        status: new FormControl(l.ds_config),
        balance: new FormControl(l.positive ? 'positive' : 'negative')
      }));
    });
    const formControlArray = new FormArray(group1);
    group.push(new FormGroup({
      options: formControlArray,
      selectedOptions: new FormControl(ApiService.mapItems(formControlArray.value)),
    }));
    formControlArray.valueChanges.subscribe((v) => {
      // console.log(group[0]);
      group[0].controls.selectedOptions.setValue(ApiService.mapItems(v));
    });
    return group[0];
    /* if (group[0].values.length >= 5 ) {
       return group[0];
     } else {
       return group[0].status = 'INVALID';
     }*/
  }
}
