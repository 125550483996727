// Some of the code is being commented out in this file. 
// If you are working on it then you can check the date of the commented code 
// and if it is older than 6 months and the code is working fine then you can go on to delete the commented code

import { Component, OnInit} from "@angular/core";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { ApiService } from "src/app/module/corporate/services/ApiService/api.service";
import { AuthService } from "src/app/services/auth.service";
import { environment } from "src/environments/environment";
import { AlertDialogComponent } from "../alert-dialog/alert-dialog.component";

@Component({
  selector: "packagesapp-index-page",
  templateUrl: "./index-page.component.html",
  styleUrls: ["./index-page.component.scss"],
})
export class IndexPageComponent implements OnInit {
  visible: boolean;
  videoHover = false;
  userPLan: any;
  plan: any;
  currentPlan: number;
  planList: any;
  newplanList: any;
  planData2: any = [];
  selected: any = 0;
  email_id: any = "";
  env = environment;
  userSubscriber: any;
  learnMore: boolean = false;
  user = {
    name: localStorage.getItem("comp_admin"),
    avatar: localStorage.getItem("avatar"),
  };
  campaign_step = ["Upload asset & create campaign", "Configure audience", "Attach pre-survey", "Attach post-survey", "Review and checkout",];
  sections = [
    {
      name: "Section 1",
      active_image: this.env.asset + "index/emotion.png",
      data: [
        {
          active: true,
          name: "Emotion Analysis",
          image: this.env.asset + "index/emotion.png",
          list: ["Valance graph", "Peak and valley", "Live graph", "Per minute and per second", "Multiple line graph"],
        },
        {
          active: false,
          name: "Reaction Analysis",
          image: this.env.asset + "index/emotion.png",
          list: ["Valance graph", "Peak and valley", "Live graph", "Per minute and per second", "Multiple line graph"],
        },
      ],
    },
    {
      name: "Section 2",
      active_image: this.env.asset + "index/salient.png",
      data: [
        {
          active: true,
          name: "Salient Scene",
          image: this.env.asset + "index/salient.png",
          list: ["Default salient scene generation", "Create in one click", "Tag emotion reaction"],
        },
      ],
    },
    {
      name: "Section 3",
      active_image: this.env.asset + "index/comments.png",
      data: [
        {
          active: true,
          name: "Live comments",
          image: this.env.asset + "index/salient.png",
          list: ["Default salient scene generation", "Create in one click", "Tag emotion reaction"],
        },
        {
          active: false,
          name: "Heatmap",
          image: this.env.asset + "index/emotion.png",
          list: ["Valance graph", "Peak and valley", "Live graph", "Per minute and per second", "Multiple line graph"],
        },
        {
          active: false,
          name: "Wordcloud",
          image: this.env.asset + "index/emotion.png",
          list: ["Valance graph", "Peak and valley", "Live graph", "Per minute and per second", "Multiple line graph"],
        },
      ],
    },
    {
      name: "Section 4",
      active_image: this.env.asset + "index/compare.png",
      data: [
        {
          active: true,
          name: "Asset Comparison",
          image: this.env.asset + "index/salient.png",
          list: ["Default salient scene generation", "Create in one click", "Tag emotion reaction"],
        },
        {
          active: false,
          name: "Valance Comparison",
          image: this.env.asset + "index/emotion.png",
          list: ["Valance graph", "Peak and valley", "Live graph", "Per minute and per second", "Multiple line graph"],
        },
      ],
    },
  ];
  packages = [
    {
      name: "Pay As You Go",
      price: "0",
      items: [
        { name: "Asset", value: "-" },
        { name: "Content", value: "1 min" },
        { name: "Duration", value: "15 days" },
      ],
    },
    {
      name: "Basic",
      price: "10000",
      items: [
        { name: "Asset", value: "2" },
        { name: "Content", value: "5 min" },
        { name: "Duration", value: "10 days" },
      ],
    },
    {
      name: "Advance",
      price: "15000",
      items: [
        { name: "Asset", value: "5" },
        { name: "Content", value: "5 min" },
        { name: "Duration", value: "5 days" },
      ],
    },
    {
      name: "ENTERPRISE",
      price: "99000",
      items: [
        { name: "Asset", value: "2" },
        { name: "Content", value: "5 min" },
        { name: "Duration", value: "5 days" },
      ],
    },
  ];
  myStyle: any;
  myParams: any;
  width = 100;
  height = 100;
  constructor(
    private _as: AuthService,
    private router: Router,
    private _aps: ApiService,
    private dialog: MatDialog
  ) { }
  ngOnInit() {
    // console.log("Hello from index page");
    if(localStorage.getItem('api_token')){
      this._as.forceLogout()
    }
    
    localStorage.removeItem("startPlan");
    localStorage.removeItem("cnt_url");
    localStorage.removeItem("cnt_name");
    localStorage.removeItem("cmp_id");
    localStorage.removeItem("cnt_id");
    localStorage.removeItem("cnt_map_id");
    localStorage.removeItem("cmp_name");
    // this._as.logOut(true);
    this.myStyle = {
      position: "fixed",
      width: "100%",
      height: "100%",
      "z-index": -1,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    };
    this.myParams = {
      particles: {
        number: {
          value: 100,
          density: {
            enable: true,
            value_area: 800,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
        },
        opacity: {
          value: 0,
          random: false,
        },
        size: {
          value: 2,
          random: false,
          anim: {
            enable: false,
          },
        },
        line_linked: {
          enable: true,
          opacity: 0.2,
        },
        move: {
          enable: true,
          speed: 1,
          direction: "none",
          random: true,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          bubble: {
            distance: 200,
            size: 2,
            duration: 2,
            opacity: 1,
            speed: 3,
          },
        },
      },
      retina_detect: true,
    };
    // console.log(this.planData.values);
    // plan page code
    this._as.getUserPLan$.subscribe((plan) => {
      if (plan) {
        this.userPLan = plan;
      }
    });
    this._as.profile$.subscribe((m) => {
      if (m) {
        this.user = m;
      }
    });
    this.plan = {};
    this.currentPlan = +localStorage.getItem("plan_id");
    this.email_id = localStorage.getItem("email");
    // this._aps.userGet('getPlans').subscribe( success => {});
    const audience_reach = { name: 'Audience Reach', Values: [] }
    const analytics_capture = { name: 'Analytics capture', Values: [] }
    const audience_composition = { name: 'Audience Composition', Values: [] }
    const monet_custom_report = { name: 'Monet Custom Report', Values: [] }
    const advance_reporting_features = { name: 'Advance Reporting Features', Values: [] }
    const reporting = { name: 'Reporting', Values: [] }
    const survey = { name: 'Survey', Values: [] }
    const turnaround = { name: 'Turnaround', Values: [] }
    const headers = [audience_reach, analytics_capture, audience_composition, monet_custom_report, advance_reporting_features, reporting, survey, turnaround]

    this._aps.userGet('getNewPlans').subscribe(success => {
      if (success && !success['error']) {
        // console.log(success);

        this.planList = success['response'];
        this.newplanList = [{ name: '', price: '', validity: '', plan_id: '', contact_us: 0, p_type: '' }];
        this.planData2.push({ name: 'Audience reach', values: [[{ name: ['Assets per year'], tooltip: '' }], [{ name: ['Video Length Limit'], tooltip: '' }], [{ name: ['Maximum Number of Respondents'], tooltip: '' }], [{ name: ['Bring Your Own Panels'], tooltip: '' }], [{ name: ['Monet Panels'], tooltip: '' }]] },
          { name: 'Audience Compositions', values: [[{ name: ['Quotas'] }], [{ name: ['Quota Change'] }], [{ name: ['Country'] }]] },
          // Replaced the Reaction Flexibility with Reaction Emoji Options (by Panakj Phour) on March 09 2023
          { name: 'Analytic Captures', values: [[{ name: ['Emotions'] }], [{ name: ['Reaction Emoji Options'] }], [{ name: ['Eye Trace'] }], [{ name: ['Mouse Hover/Click'] }]] },
          { name: 'Survey', values: [[{ name: ['Default Survey'] }], [{ name: ['Custom questions'] }], [{ name: ['Extended Features'] }]] },
          { name: 'Turnaround', values: [[{ name: ['Turnaround'] }]] },
          { name: 'Reporting', values: [[{ name: ['Overview Page'] }], [{ name: ['Detailed Page'] }], [{ name: ['Monet Metrics'] }], [{ name: ['Normative Comparisons'] }], [{ name: ['Surveys'] }], [{ name: ['Frame by frame emotion graph'] }], [{ name: ['Frame by frame reaction graph'] }], [{ name: ['Cohorts Analysis'] }]] },
          { name: 'Advanced Reporting Features', values: [[{ name: ['Salient Scenes'] }], [{ name: ['Eye Trace Graph'] }], [{ name: ['Mouse Hover/Click'] }], [{ name: ['Segmentation Analytics of Emotion and Reaction with Surveys'] }], [{ name: ['A/B Comparison Features'] }]] },
          { name: 'Monet Custom Report', values: [[{ name: ['Optional Powerpoint with salient scenes, standard report'] }], [{ name: ['Additional creative insights (custom)'] }]] },
        )
        success['response'].map((pln, index) => {
          // console.log("All Plan",pln);

          if (!pln.trail) {
            this.newplanList.push({ name: pln.p_name, price: pln.p_price, validity: pln.p_validate_plan, plan_id: pln.p_id, contact_us: pln.p_validate_plan, p_text: pln.p_text, p_text_na: pln.p_text_na, p_type: pln.p_type, p_text_price: pln.p_text_price });
          }

          for (let i = 0; i < headers.length; i++) {
            headers[i].name = headers[i].name.toLowerCase();
            headers[i].name = headers[i].name.replace(" ", "_")
            headers[i].name.search(' ') !== -1 ? headers[i].name = headers[i].name.replace(" ", "_") : '';
            headers[i].Values.push(pln[headers[i].name])
          }
          for (let i = 0; i < audience_reach.Values[0].length; i++) {
            this.planData2[0].values[i].push({ name: audience_reach.Values[index][i].v });
            this.planData2[0].values[i][0].tooltip = audience_reach.Values[0][i].i;
          }
          for (let i = 0; i < audience_composition.Values[0].length; i++) {
            this.planData2[1].values[i].push({ name: audience_composition.Values[index][i].v });
            this.planData2[1].values[i][0].tooltip = audience_composition.Values[0][i].i;
          }
          for (let i = 0; i < analytics_capture.Values[0].length; i++) {
            this.planData2[2].values[i].push({ name: analytics_capture.Values[index][i].v });
            this.planData2[2].values[i][0].tooltip = analytics_capture.Values[0][i].i;
          }
          for (let i = 0; i < survey.Values[0].length; i++) {
            this.planData2[3].values[i].push({ name: survey.Values[index][i].v });
            this.planData2[3].values[i][0].tooltip = survey.Values[0][i].i;
          }
          for (let i = 0; i < turnaround.Values[0].length; i++) {
            this.planData2[4].values[i].push({ name: turnaround.Values[index][i].v });
            this.planData2[4].values[i][0].tooltip = turnaround.Values[0][i].i;
          }
          for (let i = 0; i < reporting.Values[0].length; i++) {
            this.planData2[5].values[i].push({ name: reporting.Values[index][i].v });
            this.planData2[5].values[i][0].tooltip = reporting.Values[0][i].i;
          }
          for (let i = 0; i < advance_reporting_features.Values[0].length; i++) {
            // console.log(advance_reporting_features,index,i,this.planData2)
            this.planData2[6].values[i].push({ name: advance_reporting_features.Values[index][i].v });
            this.planData2[6].values[i][0].tooltip = advance_reporting_features.Values[0][i].i;
          }
          for (let i = 0; i < monet_custom_report.Values[0].length; i++) {
            this.planData2[7].values[i].push({ name: monet_custom_report.Values[index][i].v });
            this.planData2[7].values[i][0].tooltip = monet_custom_report.Values[0][i].i;
          }

        });
        this.newplanList.shift();
      }
      // console.log(this.newplanList);

    });
  }

  goToStep(e) {
    this.learnMore = e
    window.scrollTo(0, 0)
  }
  scroll(el: HTMLElement) {
    console.log(el)
    el.scrollIntoView({ behavior: "smooth", block: "start" });
  }
  onCollapseSection(sectionData, index) {
    sectionData.map(
      (d, i) => (d.active = i === index ? !sectionData[index].active : false)
    );
  }

  logout() {
    const data = {
      head: "Confirmation",
      msg: "Do you want to logout?",
      yes: "Logout",
      no: "Cancel",
    };
    const dialogref = this.dialog.open(AlertDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      data: data,
      width: "350px",
      height: "250px",
      panelClass: "deleteCampaigns",
    });
    // console.log(data)
    dialogref.afterClosed().subscribe((next) => {
      // console.log(next)
      if (next === "yes") {
        this._aps.userGet("logout").subscribe((success) => {
          if (success) {
            localStorage.clear();
            sessionStorage.clear();
            // this.visible = false;
            this.router.navigate(["../../landingPage"]);
            this._as.obNotify({
              status: "success",
              message: "Logged out",
              start: true,
              code: 200,
            });
          }
        });
      }
    });
  }

  addActiveClass(el) {
    el.style.transform = "scale(1)";
  }
  removeActiveClass(el) {
    el.style.transform = "scale(0.7)";
    el.style.transition = "all 0.7s linear";
  }
  // Added this function to save the page location details in the localStorage (by Pankaj Phour) on March 14 2023 
  routePlan() {
    console.log("Routing to plan page");
    this.router.navigate(['./plans'])
  }

  register() {
    this.router.navigate(['./auth'], { queryParams: { 'register': true } })
  }
  
  login(){
    this.router.navigate(['./auth'])

  }
  toggle(e: any) {
    this.selected = e.checked ? 1 : 0;
  }

  PlanDetails(){
    // window.location.href = "127.0.0.1/#/plans"
    let url =  window.location.href;
    console.log(url,url.indexOf('192.168'));
    if(url.indexOf('corporate_1.5') !== -1){
      window.open('https://dev.monetrewards.com/corporate_1.5/#/plans','_blank');
    }
    else if(url.indexOf('corporate.monetanalytics') !== -1){
      window.open('https://corporate.monetanalytics.com/#/plans','_blank')
    }
    else if(url.indexOf('production_testing') !== -1){
      window.open('https://dev.monetrewards.com/production_testing/#/plans','_blank');
      
    }
    else{
      window.open('127.0.0.1/#/plans','_blank')

    }
  }
}
