// Some of the code is being commented out in this file.
// If you are working on it then you can check the date of the commented code
// and if it is older than 6 months and the code is working fine then you can go on to delete the commented code
import {
	Component,
	EventEmitter,
	//  HostListener,
	OnInit,
	Output,
	//  Input
} from '@angular/core';
import {
	AbstractControl,
	FormBuilder,
	FormControl,
	FormGroup,
	//  ValidationErrors,
	Validators
} from '@angular/forms';
import {
	map,
	startWith
} from 'rxjs/operators';
import {
	AuthService
} from '../../services/auth.service';
// import { ActivatedRoute, Router} from '@angular/router';
import {
	Observable
} from 'rxjs';
// import {string} from '@amcharts/amcharts4/core';
// import {appendChild} from '@angular/core/src/render3/node_manipulation';
import {
	environment
} from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
// const Name_REGEX = /^[A-Za-z][A-Za-z .]{3,100}$/;
const Name_REGEX = /^(?=.*[a-z\d]).+/i;
// *********************************  Changed the regex format from 2,3 to 2,5 in the end (by Pankaj Phour) on february 13 2023  *********************************
const EMAIL_REGEX = /^[a-zA-Z]+[a-zA-Z0-9._]+@[a-zA-Z]+\.[a-zA-Z.]{2,8}$/;
// const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[_!@#$%^&'])[^ ]{8,}$/;
// const whitespace_regex = /^([a-zA-Z0-9][^\s])+([\a-zA-Z0-9]).(\s)$/;
const whitespace_regex = /^([a-zA-Z0-9_\-\.]+)/;
// const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[_!@#$%^&'])[^ ]{8,}$/;
const PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*\d)(?=.*[_!@#$%^&*()+=;:'",<.>/?~`-])[^ ]{8,}$/;
const allow_number = /^[0-9]*$/;

export interface Country {
	id: string;
	country_name: string;
	country_code: string;
	country_id: number;
	_id: string;
}

function passwordMatcher(c: AbstractControl) {
	if (c.get('password').valid && c.get('password_confirmation').valid) {
		return c.get('password').value === c.get('password_confirmation').value ?
			null : {
				'nomatch': 'password not matching'
			};
	} else {
		return {
			invalid: 'some invalid fields exist'
		}
	}
}
@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit {

	@Output() tabChange = new EventEmitter();
	loading = false;
	country: Country[] = [];
	hide = true;
	chide = true;
	registerForm: FormGroup;
	registrationForm: FormGroup;
	error: string;
	error_show = true;
	searchCountry = new FormControl();
	count: any = 1;
	filteredCountries: Observable<Country[]>;
	env = environment;
	plan_id:any;
	formPart = {
		0: ['c_name', 'c_email', 'country', 'z_code'],
		1: ['admin', 'password', 'password_confirmation', 'c_address']
	};
	fields = [
    {
		name: 'company_name',
		validations: [{
			name: 'required',
			message: 'Company is required'
		},
		{
			name: 'pattern',
			message: 'Company should be character'
		},
		{
			name :'minlength',
			message : 'Company name must contain at least 3 letters'
		},
		{
			name :'maxlength',
			message : 'Maximum 100 characters allowed in company'
		}
		],
		placeholder: 'Company',
		label: 'Some',
		child: [],
		type: 'text'
	},
	{
		name: 'company_admin',
		validations: [{
			name: 'required',
			message: 'Admin is required'
		},
		{
			name: 'pattern',
			message: 'Admin should be character'
		},
		{
			name : 'minlength',
			message : 'Admin name must contain at least 3 letters'
		},
		{
			name : 'maxlength',
			message : 'Maximum 100 characters allowed in admin'
		}
		],
		placeholder: 'Admin',
		label: 'Some',
		child: [],
		type: 'text'
	},
	{
		name: 'company_email',
		validations: [{
			name: 'required',
			message: 'Email is required'
		},
		{
			name: 'pattern',
			message: 'Invalid email'
		}
		],
		placeholder: 'Email',
		label: 'Some',
		child: [],
		type: 'text'
	},
	{
		name: 'company_password',
		validations: [{
			name: 'required',
			message: 'Password is required'
		},
		{
			name: 'pattern',
			message: 'Must be 8 character long containing a special character, number and a capital letter'
		},
		{
			name: 'nomatch',
			message: 'Password aren\'t matching'
		}
		],
		placeholder: 'Password',
		label: 'Some',
		child: [{
			name: 'password',
			validations: [{
				name: 'required',
				message: 'Password is required'
			},
			{
				name: 'pattern',
				message: 'Password should be of 8 characters (at least one digit, one lower case, one upper case)'
			}
			],
			placeholder: 'Password',
			label: 'Some',
			type: 'password',
			hide:true
		},
		{
			name: 'password_confirmation',
			validations: [{
				name: 'required',
				message: 'Password is required'
			},
			{
				name: 'pattern',
				message: 'Password should be of 8 characters (at least one digit, one lower case, one upper case)'
			}
			],
			placeholder: 'Confirm Password',
			label: 'Some',
			type: 'password',
			hide:true
		},
		],
		type: 'text'
	},
  {
    name:'zip_country',
    label:'Some',
    placeholder:'PlaceHolder',
    text:'text',
    child:[
      {
        name: 'company_country',
        validations: [{
          name: 'required',
          message: 'Password is required'
        },],
        placeholder: 'Select Country',
        label: 'Some',
        child: [],
        type: 'select'
      },
	{
		name: 'company_zip_code',
		validations: [{
			name: 'required',
			message: 'Zip code is required'
		},
		{
			name: 'pattern',
			message: 'Invalid zipcode'
		},
		{
			name : 'minlength',
			message :'Zip code must contain at least 3 letters'
		},
		{
			name : 'maxlength',
			message :'Maximum 15 characters allowed in zipcode'
		}
		],
		placeholder: 'Zip Code',
		label: 'Some',
		child: [],
		type: 'text'
	},
  ]},
	{
		name: 'company_address',
		validations: [{
			name: 'required',
			message: 'Company address is required'
		},
		{
			name: 'pattern',
			message: 'Invalid company address'
		},
		{
			name :'minlength',
			message : 'Company address must contain at least 3 letters'
		},
		{
			name :'maxlength',
			message : 'Maximum 100 characters allowed in address'
		}
		],
		placeholder: 'Address',
		label: 'Some',
		child: [],
		type: 'textarea'
	},

	];
	constructor(
		private fb: FormBuilder,
		private _as: AuthService,
		//  private router: Router,
		 private _route: ActivatedRoute
	) {
		this.registrationForm = this.fb.group({
			// company_name: ['',[Validators.required, Validators.pattern(Name_REGEX)]],
			company_name: ['', Validators.compose([Validators.required, Validators.minLength(3),Validators.maxLength(100), Validators.pattern(Name_REGEX)])],
			// company_admin: ['',[Validators.required, Validators.pattern(Name_REGEX)]],
			company_admin: ['', Validators.compose([Validators.required, Validators.minLength(3),Validators.maxLength(100), Validators.pattern(Name_REGEX)])],
			company_email: ['', Validators.compose([Validators.required, Validators.pattern(EMAIL_REGEX)])],
			company_password: this.fb.group({
				password: ['', Validators.compose([Validators.required, Validators.pattern(PASSWORD_REGEX)])],
				password_confirmation: ['', Validators.compose([Validators.required, Validators.pattern(PASSWORD_REGEX)])],
			}, {
				validator: passwordMatcher
			}),
      zip_country : this.fb.group({
        company_zip_code: ['', Validators.compose([Validators.required,Validators.minLength(3),Validators.maxLength(15), Validators.pattern(whitespace_regex), Validators.pattern(allow_number)])],
        company_country: [2, Validators.compose([Validators.required])],
      }),
			company_address: ['', Validators.compose([Validators.required,Validators.minLength(3),Validators.maxLength(100), Validators.pattern(whitespace_regex)])],
			verification_link: window.location.origin + '/v2.2/',
			p_id : '1'
		});
	}

	validateSpecialCharacter(control: AbstractControl): {
		[key: string]: boolean
	} | null {
		const NUMBER = Array.from(Array(10).keys());
		const SPECIAL_CHARACTOR = "!`@#$%^&*()+=-[]\\\';,./{}|\":<>?~_ ";
		const NOT_ALLOW = SPECIAL_CHARACTOR + NUMBER;
		if (!control.value || control.value.trim().length === 0 || Object.assign([], control.value).every(d => NOT_ALLOW.includes(d))) {
			return {
				'required': true
			};
		}
		return null;
	}
	/* @HostListener('paste', ['$event']) blockPaste(e:  KeyboardEvent) {
	   e.preventDefault();
	 }

	 @HostListener('copy', ['$event']) blockCopy(e:  KeyboardEvent) {
	   e.preventDefault();
	 }

	 @HostListener('cut', ['$event']) blockCut(e:  KeyboardEvent) {
	   e.preventDefault();
	} */

	createForm() {
		/* this.registerForm = this.fb.group({
		   second_part: this.fb.group({
			 passwords :  this.fb.group({
			   password:  ['', Validators.compose([Validators.required, Validators.pattern(PASSWORD_REGEX)])],
			   password_confirmation:  ['', Validators.compose([Validators.required, Validators.pattern(PASSWORD_REGEX)])],
			 }, { validator:  passwordMatcher }),
			 admin:  ['', Validators.compose([Validators.required])],
			 c_address:  ['', Validators.compose([Validators.required])],
		   }),
		   first_part: this.fb.group({
			 c_email:  ['', Validators.compose([Validators.required, Validators.pattern(EMAIL_REGEX)])],
			 c_name:  ['', Validators.compose([Validators.required])],
			 z_code:  ['', Validators.compose([Validators.required])],
			 country:  ['', Validators.compose([Validators.required])],
		   })
		}); */
		/* this.registrationForm = this.fb.group({
		   company_name: ['', Validators.compose([Validators.required])],
		   company_admin: ['', Validators.compose([Validators.required])],
		   company_email: ['', Validators.compose([Validators.required, Validators.pattern(EMAIL_REGEX)])],
		   company_password: this.fb.group({
			 password:  ['', Validators.compose([Validators.required, Validators.pattern(PASSWORD_REGEX)])],
			 password_confirmation:  ['', Validators.compose([Validators.required, Validators.pattern(PASSWORD_REGEX)])],
		   }, passwordMatcher),
		   company_zip_code: ['', Validators.compose([Validators.required])],
		   company_address: ['', Validators.compose([Validators.required])],
		   company_country: [2, Validators.compose([Validators.required])],
		   verification_link: window.location.origin + '/diy_2.0/#/'
		}) */
	}

	private _filter(name: string): Country[] {
		const filterValue = name.toLowerCase();
		return this.country.filter(option => option.country_name.toLowerCase().indexOf(filterValue) === 0);
	}

	get_countries() {
		this._as.userGet('getCountries').subscribe(
			(success) => {
				if (success['code'] === 200) {
					this.country = success['response'];
					this.filteredCountries = this.searchCountry.valueChanges
						.pipe(
							startWith<string | Country>(''),
							map(value => typeof value === 'string' ? value : value.country_name),
							map(countries_name => countries_name ? this._filter(countries_name) : this.country.slice())
						);
				} else if (parseInt(success['code'], 10) === 400 || parseInt(success['code'], 10) === 404 || parseInt(success['code'], 10) === 403 || parseInt(success['code'], 10) === 409 || parseInt(success['code'], 10) === 412) {
					this.error = success['message'];
					this.error_show = true;
					setTimeout(() => {
						this.error_show = false;
					}, 2500);
				}
			},
			() => { }
		);
	}

	validate() {
		// console.log(Object.keys(this.registerForm.value));
		return false;
	}
	createAccount(form: FormGroup) {
		this.loading = true;
		// console.log(this.registrationForm);
		if (form.invalid) {
			this.loading = false;
			let list = [];
			Object.keys(form.value).map(m => {
				form.get(m).markAsTouched();
				if(!form.value[m]){
					list.push(m)
				}
			});
			const a = document.getElementById(list[0]);
			if(a){

				a.scrollIntoView({behavior:'smooth'})
			}
			return this._as.obNotify({
				start: true,
				message: 'All fields are required',
				status: 'error',
				code: 400
			});
		}
		const value = form.value;
		value.company_password = value.company_password.password;
		value.company_zip_code = value.zip_country.company_zip_code;
		value.company_country = value.zip_country.company_country;
		value.company_email = value.company_email.toLowerCase();
		value.p_id = this.plan_id ? this.plan_id : '1'
		this._as.userPost(form.value, 'register').subscribe((success) => {
			this.loading = false;
			if (!success['error']) {
				setTimeout(() => {
					this.tabChange.emit(true);
					this.registrationForm.reset();
				}, 1000);
				this._as.obNotify({
					status: 'info',
					message: success['message'],
					start: true,
					code: 200
				});
			} else {
				this._as.obNotify({
					status: 'error',
					message: success['message'],
					start: true,
					code: 400
				});
			}
		});
	}

	ngOnInit() {
		this._route.queryParams.subscribe((params:any)=>{
			if(params.plan_id){
				this.plan_id = params.plan_id;
			}
		})
		this.get_countries();
		this.createForm();
	}


}
