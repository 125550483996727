export const environment = getAPI(false);

function getAPI(prod) {
  return {
    production: false,
    prod: prod,
    watchUrl: prod ? 'diyuser' : 'diyuser',
    watchUrl3: prod ? 'diyuser' : 'user_register',
    watchLink: prod ? {
      server: 'user.monetanalytics.com/',
      build: '#/l',
      // Added the below two modules for different redirects on frontEnd of user side  on january 11 2023 (By Pankaj Phour )
      moduleSvf:'#/svf/',
      moduleDiy:'#/diy/',
      base: 'user/main/Auth'
    } : {
      // server: 'dev.monetrewards.com/diy_user_beta',
      // server: 'dev.monetrewards.com/monetuser24', // updated for dashboard link copy url By Pankaj Phour
      server: 'user.monetanalytics.com/', // updated for watch link for user side on january 11 2023 ( By Pankaj Phour )
      build: '#/l',
      // Added the below two modules for different redirects on frontEnd of user side  on january 11 2023 (By Pankaj Phour )
      moduleSvf:'#/svf/',
      moduleDiy:'#/diy/',
      base: 'user/main/Auth'
    },
    asset: 'https://monet-assets.s3-us-west-2.amazonaws.com/icons/corporate/',
    // asset: 'assets/',
    // apiUrl: prod ? 'https://dev.monetrewards.com/diycorporate1.0/api/' : 'https://dev.monetrewards.com/DiyLaraTest/api/',
    // apiUrl: prod ? 'https://dev.monetrewards.com/diycorporate1.2/api/' : 'https://dev.monetrewards.com/DiyLaraDev/api/',
   // apiUrl: prod ? 'https://www.monetrewards.com/CorporateApi/api/' : 'https://dev.monetrewards.com/DiyClusterDev/api/',
   // apiUrl: prod ? 'https://www.monetrewards.com/CorporateApi1.2/api/' : 'https://dev.monetrewards.com/DiyClusterDev/api/',
   // apiUrl: prod ? 'https://www.monetrewards.com/CorporateApi1.2/api/' : 'https://diyapi2.monetanalytics.com/api/',
  // apiUrl: prod ? 'https://www.monetrewards.com/CorporateApi1.2/api/' : 'https://dev.monetrewards.com/Monet-diy-api-beta/api/',

                         // Api changed by Pankaj Phour on july 26 2022
  // apiUrl: prod ? 'https://diyapi2.monetanalytics.com/api/' : 'https://task.monetrewards.com/Monet-diy-api-beta/api/',

//  ***********************  Api changed by Pankaj Phour on december 30 2022  (For update on production ) ***********************
  apiUrl: prod ? 'https://diyapi.monetanalytics.com/api/' : 'https://diyapi.monetanalytics.com/api/',
  // apiUrl: prod ? 'http://35.164.127.80/Monet-diy-api/api/' : 'http://35.164.127.80/Monet-diy-api/api/',
  // apiUrl: prod ? 'https://task.monetanalytics.com/Monet-diy-api/api/' : 'https://task.monetanalytics.com/Monet-diy-api/api/',


  // APi for Surender rawat IP address  for testing purpose only
  // apiUrl: prod ? 'http://192.168.1.25:8125/api/' : 'http://192.168.1.25:8125/api/',
  // apiUrl: prod ? 'http://34.221.94.220/Monet-diy-api/api/' : 'http://34.221.94.220/Monet-diy-api/api/',





  // This url is for eemo campaign upload in corporate by Pankaj Phour on august 25 2022

  // ajayApi: prod ? 'https://task.monetrewards.com/Monet-diy-api-beta/api/' : 'https://task.monetrewards.com/Monet-diy-api-beta/api/',
  // ajayApi: prod ? 'https://www.eemo.live/Monet-quick/api/' : 'https://www.eemo.live/Monet-quick/api/',


  // ashwaniApi: 'http://192.168.1.152:3000/',
  // apiUrl: prod ? 'https://diyapi2.monetanalytics.com/api/' : 'https://diyapi2.monetanalytics.com/api/',

  // apiUrl: prod ? 'https://www.monetrewards.com/CorporateApi1.2/api/' : 'https://dev.monetrewards.com/CorporateTestApi/api/',
    // apiUrl: prod ? 'https://www.monetrewards.com/CorporateApi1.2/api/' : 'https://dev.monetrewards.com/DiyCorporateDev/api/',
    /*DiyCorporateDev*/
    // apiUrl: prod ? 'https://dev.monetrewards.com/DiyClusterDev/api/' : 'https://dev.monetrewards.com/DiyClusterTest/api/' ,
    // apiUrlIP: prod ? 'https://diydata-prod.monetrewards.com/' : 'https://diydata-test.monetrewards.com/',

                // Api Change by Pankaj Phour (replaced with node Api by vikas rathi)
    // apiUrlIP: prod ? 'https://diydata-test.monetrewards.com/' : 'https://diydata.monetrewards.com/',
    // apiUrlIP: prod ? 'http://34.221.99.102:3000/' : 'http://34.221.99.102:3000/',

    // Changed the base url from ip to domain by Pankaj Phour
    // apiUrlIP: prod ? 'http://34.221.99.102:3000/' : 'http://34.221.99.102:3000/',
    // apiUrlIP: prod ? 'https://dev.eemo.live/diy/' : 'https://dev.eemo.live/diy/',


    // ************************************************** Changed by Pankaj Phour on august 17 2022 **************************************************
    // apiUrlIP: prod ? 'https://nodeapi.monetanalytics.com/' : 'https://nodeapi.monetanalytics.com/',

    // ***********************************************  Changed the url again on 2 january 2023 ordered by saurav sir ( By Pankaj Phour)  *************************************************
    // apiUrlIP: prod ? 'https://node.monetanalytics.com/' : 'https://node.monetanalytics.com/',

    // APi for dev server used by Pankaj Phour (for testing purpose only)
    apiUrlIP: prod ? 'https://node.monetanalytics.com/' : 'https://node.monetanalytics.com/',
    // URL of ashwani kumar local ip to test backend code
    // apiUrlIP: prod ? 'http://192.168.1.152:3000/' : 'http://192.168.1.152:3000/',

    // URL for ashish local ip to test backend code
    // apiUrlIP: prod ? 'http://192.168.1.79:3000/' : 'http://192.168.1.79:3000/',

    // URL for shubhang local ip to test backend code
    // apiUrlIP: prod ? 'http://192.168.1.152:3000/' : 'http://192.168.1.152:3000/',




    // Changed again  the above base url by Pankaj Phour (replace with the link given by Surender Rawat on july 26 2022)
    // apiUrlIP: prod ? 'https://diydata-test.monetrewards.com/' : 'https://diydata-test.monetrewards.com/',





    // apiUrlIP: 'https://diydata-test.monetrewards.com/',



    // apiUrlIP: prod ? 'https://diydata-prod.monetrewards.com/' : 'https://diydata-test.monetrewards.com/',
    // storageUrl: prod ? 'https://www.monetrewards.com/CorporateApi/' : 'https://dev.monetrewards.com/DiyLaraTest',
    // storageUrl: prod ? 'https://www.monetrewards.com/CorporateApi/' : 'https://dev.monetrewards.com/MySqlMonet2',
   // storageUrl: prod ? 'https://www.monetrewards.com/CorporateApi1.2/' : 'https://diyapi2.monetanalytics.com',
   storageUrl: 'https://monet-assets.s3-us-west-2.amazonaws.com',
    // nodeStorage: prod ? 'https://diyapi2.monetanalytics.com' : 'https://diyapi2.monetanalytics.com',


    nodeStorage: 'https://monet-assets.s3-us-west-2.amazonaws.com',
  };
}
