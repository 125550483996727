import {ElementRef, Injectable, EventEmitter, Output} from '@angular/core';
import { environment } from '../../environments/environment';
import {HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, forkJoin, Observable, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {FormGroup, ValidationErrors} from '@angular/forms';
import { catchError, debounce, last, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public tutorialElements = [];
  public tutorial = false;
  // public showTutorial = localStorage.getItem('has_cmp') === 'true' ? false : true;
  public ngAfterCount = 0;
  public preSurvey: ElementRef;
  currentUrl: any;
  constructor(private http: HttpClient, private router: Router) {
    this.currentUrl = this.router.url;
    this.router.events.subscribe( next => this.currentUrl = this.router.url );
  }


  public contactValueEvent = new EventEmitter();
  contactF(data:any){
    this.contactValueEvent.emit(data)

  }


  // Event emitter for sending the log out info to the top component 
  @Output() logOutEvent = new EventEmitter();
  logOut(data:any){
    this.logOutEvent.emit(data)
  }

  // Event emitter for sending the log In info to the top component 
  @Output() logInEvent = new EventEmitter();
  logIn(data:any){
    this.logInEvent.emit(data)
  }


  // Event emitter for sending the pending payment info to the dashboard component 
  @Output() pendingPaymentEvent = new EventEmitter();
  pendingPayment(data:any){
    this.pendingPaymentEvent.emit(data)
  }


  // Event emitter for sending the pending payment info to the dashboard component 
  @Output() planUpgradeEvent = new EventEmitter();
  getUserForTop(data:any){
    this.planUpgradeEvent.emit(data)
  }



  private _notify = new BehaviorSubject<any>({status: 'success', message: 'message', start: false, code: 200});
  notify$ = this._notify.asObservable();

  private _cart = new BehaviorSubject<any>('');
  cart$ = this._cart.asObservable();


  settings = new BehaviorSubject<any>('');

  private _surveyChange = new BehaviorSubject<any>('');
  _surveyChange$ = this._surveyChange.asObservable();

  private _activePlan = new BehaviorSubject<any>('');
  activePlan$ = this._activePlan.asObservable();

  private _addTakeAway = new BehaviorSubject<any>('');
  addTakeAway$ = this._addTakeAway.asObservable();

  private _takeAway = new BehaviorSubject<any>('');
  takeAway$ = this._takeAway.asObservable();

  private _walkthrough = new BehaviorSubject<any>('');
  walkthrough$ = this._walkthrough.asObservable();

  private _profile = new BehaviorSubject<any>('');
  profile$ = this._profile.asObservable();

  private _getUserPLan = new BehaviorSubject<any>('');
  getUserPLan$ = this._getUserPLan.asObservable();


  private _getUserPLanAllData = new BehaviorSubject<any>('');
  _getUserPLanAllData$ = this._getUserPLanAllData.asObservable();

  private _opacity = new BehaviorSubject<any>('');
  opacity$ = this._opacity.asObservable();

  private _test_type = new BehaviorSubject<any>('');
  test_type$ = this._test_type.asObservable();
  /*  private _steperChange = new BehaviorSubject<any>('');
    steperChange$ = this._steperChange.asObservable();

    steperChange(data: any): void {
      this._steperChange.next(data);
    }*/
  private _showTutorials = new BehaviorSubject<any>(true);
  _showTutorials$ = this._showTutorials.asObservable();

  private _urlDialog = new BehaviorSubject<any>(true);
  _urlDialog$ = this._urlDialog.asObservable();

  public disableSelect = new BehaviorSubject<any>('');
  disableSelect$ = this.disableSelect.asObservable()

  public progress = new BehaviorSubject<any>(0);
  progress$ = this.progress.asObservable()

  public plan =  new BehaviorSubject('')
  public quotaCheck =  new BehaviorSubject('')
  public planChange =  new BehaviorSubject('')
  public maxlimit = new BehaviorSubject('')


  // public maxlimit = new BehaviorSubject('')


  urlDialog(data: any): void {
    this._urlDialog.next(data);
  }

  showTutorials(data: any): void {
    this._showTutorials.next(data);
  }

  opacity(data: any): void {
    this._opacity.next(data);
  }

  obCart(data: any): void {
    this._cart.next(data);
  }
  profile(data: any): void {
    this._profile.next(data);
  }
  getUserPLanOb(data: any): void {
    // console.log("Data from auth service",data);
    this._getUserPLan.next(data);
  }

  getUserPLanAllData(data: any): void {
    // console.log("Data from auth service",data);
    this._getUserPLanAllData.next(data);
  }
  walkthrough(data: any): void {
    this._walkthrough.next(data);
  }

  obNotify(data: any): void {
    this._notify.next(data);
  }

  addTakeAway(data: any): void {
    this._addTakeAway.next(data);
  }

  test_type(data: any): void {
    this._test_type.next(data);
  }

eemoUploadold(endpoint:any, params:any){
  return this.http.post(environment.apiUrl + endpoint,params)
}

eemoUpload (endpoint,userData, index) {
  const req = new HttpRequest('POST', environment.apiUrl + endpoint, userData, {
    reportProgress: true
  });
  const res = this.http.request(req).pipe(
    map((event:any) =>{
      const progress:number = (Math.round(100 * event.loaded / event.total))
     
      
      // if(Number.isNaN(progress)){
        this.progress.next(progress)
        // console.log(progress);
      // }
     return this.getEventMessage(event,userData,index)
    }),
    last(),
  )
  return res
}

eemoContentUpload(endpoint:any, params:any){
  return this.http.post(environment.apiUrl + endpoint,params)
}

private getEventMessage(event: HttpEvent<any>, file: any, index) {
  // if (event['body'] && !event['body'].error) {
  //   this.obProgressMessage({cmp_id: event['body'].response.cnt_id, index: index});
  // }
  switch (event.type) {
    case HttpEventType.Sent:
      return `Adding asset to your campaign`;

    case HttpEventType.UploadProgress:
      // Compute and show the % done:
      return { progress : Math.round(100 * event.loaded / event.total), message: 'Asset Uploading...', index: index };

    case HttpEventType.Response:
      return event.body;

    default:
      return `Wait for a while till your asset being finalized`;
  }
}

  addTutorial(elements): void {
    this._showTutorials$.subscribe(show => {
      // console.log(show, 'show')
      if (show) {
        this.tutorialElements = [];
        this.tutorial = false;
        this.tutorialElements = elements;
      }
    });
  }

  // getCmpId(): Observable<string> {   // <-- capital 'O' instead of small 'o'
  // let availableCmpId  
  //   if(localStorage.getItem('cmp_id')){
  //     availableCmpId = true;
  //   }else{
  //     availableCmpId = false;
  //   }
  //   this.cmpIdSource.next(availableCmpId);
  //   return this.cmpIdData$;
  // }
  getCartDetails() {
    /*console.log(JSON.parse(localStorage.getItem('cluster')));
    const cluster = JSON.parse(localStorage.getItem('cluster'));*/
    // this.userGet('getCart?cmp_id=' + localStorage.getItem('cmp_id')).subscribe(success => {
    this.userGet('getCart?cmp_id=' + localStorage.getItem('cmp_id')).subscribe(success => {
      // console.log(success);
      if (success && success['response']) {
        // console.log(success['response'].contents.filter( f => f.charges));
        this.obCart(success['response']);
      }
    });
/*    contents: [{cnt_id: 2350, cnt_name: "Above the clouds sunrise timelapse.mp4", charges: [,…]}]
    0: {cnt_id: 2350, cnt_name: "Above the clouds sunrise timelapse.mp4", charges: [,…]}
    charges: [,…]
    0: {toc_id: 7, toc_p_id: 2, toc_type: "Default", toc_name: "Sample size (300)", toc_slag: "p_sample_size",…}
    toc_id: 7
    toc_name: "Sample size (300)"
    toc_p_id: 2
    toc_price: 0
    toc_slag: "p_sample_size"
    toc_type: "Default"
    toc_units: 300*/
  }
  obTakeAway(data: any): void {
    this._takeAway.next(data);
  }
  onSurveyChange(data:any):void{
    this._surveyChange.next(data);
  }
  obActivePlan(data: any): void {
    this._activePlan.next(data);
  }

  // showCampaingnCreate(data: any): void {
  //   this._campaignCreate.next(data);
  // }

  public userGet(endpoint) {
    return this.http.get(environment.apiUrl + endpoint);
  }
  public userGetIP(endpoint) {
    return this.http.get(environment.apiUrlIP + endpoint);
  }
  public userPutIP(endpoint, data) {
    return this.http.put(environment.apiUrlIP + endpoint, data);
  }
  public userPost (userData, endpoint) {
    return this.http.post(environment.apiUrl + endpoint, userData);
  }

  // This function is for calling the api for Support team on contcat us page ( By Pankaj Phour)
  public supportPost (userData, endpoint) {
    return this.http.post(environment.apiUrl + endpoint, userData);
  }


  // This API is for getting all the categories in case of SVF campaign (by Pankaj Phour) on March 17 2023 
  public getSVFCategories (endpoint) {
    return this.http.get(environment.apiUrl + endpoint);
  }
  
  get(endpoint) {
    return this.http.get(environment.apiUrl + endpoint);
  }
  forceLogout(reason:any = '') {
    this.logOut(true)
    localStorage.clear();
    sessionStorage.clear();
     this.router.navigate(['auth']);
   reason ? this.obNotify({status: 'error', message: reason, start: true, code: 200}) : '';
    /*this.router.navigate(['/corporate/inactivity', reason]);*/
    // this.router.navigateByUrl('/corporate/diy/inactivity');
  }

  public getHeaders() {
    const token = localStorage.getItem('api_token');
    if (token) {
      return { headers: new HttpHeaders({'Authorization': 'Bearer ' + token}) };
    } else {
      // this.forceLogout('token_not_provided');
    }
  }

  public forkJoin(data) {
    const res1 = this.http.post(environment.apiUrl + data.url1, data.params);
    /* const res2 = this.http.get(environment.apiUrlIP + data.url2);*/
    // console.log(data.params);
    const para = '&page=' + data.params.page + '&per_page=' + data.params.no_cmps + '&page_stage=' + data.params.page_stage_range.join('-');
    const res2 = this.http.get(environment.apiUrlIP + data.url2 + para);
    return forkJoin([res1, res2]);
  }
  public static passwordMatcher(c:  FormGroup): ValidationErrors | null {
    console.log(c);
    return c.get('password').value === c.get('password_confirmation').value
    ? null : { nomatch:  'Password doesnt match' };
  }
  public forkApi(data) {
    const joinData = data.map(m => {
      if(m.type === 'post') {
        return this.http.post(m.base +m.url, m.params);
      } else {
        let url_params = '';
        Object.keys(m.params).map((k,i) => {
          if(i === 0) {
            url_params += k+'='+m.params[k];
          } else {
            url_params += '&'+k+'='+m.params[k];
          }
        });
        return this.http[m.type](m.base+m.url+url_params);
      }
    });
    return forkJoin(joinData);
  }

  canCheckAuth(event): boolean {
    if (!event.url.match(/isValidToken/g) && !event.url.match(/getCountries/g)) {
      const lastAuthAt = sessionStorage.getItem('lastAuthAt');
      // Removed the substraction of 2 from the current time to avoid logout errors (by Pankaj Phour) on May 19 2023 
      // if (!lastAuthAt || +lastAuthAt < Math.floor(new Date().getTime() / 1000) - 2) {
      if (!lastAuthAt || +lastAuthAt < Math.floor(new Date().getTime() / 1000)) {
        sessionStorage.setItem('lastAuthAt', Math.floor(new Date().getTime() / 1000).toString());
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  loggedIn() {
    return !!localStorage.getItem('api_token');
  }

}
@Injectable()
export class IsLoggedIn {
  constructor(
    private router: Router,
    private as: AuthService) {
  }
  resolve(): void {
   // console.log('here');
    if (this.as.loggedIn()) {
      this.router.navigate(['/corporate/dashboard/campaign']);
    }
  }
}
