
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {Router} from '@angular/router';
import { ApiService } from 'src/app/module/corporate/services/ApiService/api.service';


@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PlanComponent implements OnInit {
  @ViewChild('callUsDialog',null) callUsDialog:TemplateRef<any>;
  @ViewChild('Payasyougo',null) Payasyougo:ElementRef;
  @ViewChild('planParent',null) planParent:ElementRef;
  @ViewChild('Subscription',null) Subscription:ElementRef;
  @ViewChild('textArea',null) textArea:ElementRef;
  userPLan: any;
  selected:any = 0;
  comparision:boolean = false;

  plnFix = [ {pln1 : 'Free trial',
              pln2:'Basic',
              pln3 : 'Premium',
              pln4: 'Subscription'
}]

  planData2:any = [];
  currentPlan: number;
  plan: any;
  email_id: any = '';
  planList: any;
  newplanList: any;
  user: any;
  remainingDays: any;
  sendId: any;
  hideonScroll: any;

  constructor(private _aps: ApiService,
              private router: Router) {
  }
  checkType (val) {
    return typeof val;
  }

  ngOnInit() {
    this.getUser()

    window.addEventListener('scroll',(event)=>{
     let scrolCount = Math.ceil(window.scrollY)
      if(scrolCount >= 1000){
        this.hideonScroll = true
      }else{
        this.hideonScroll = false
      }
    })
  }


  getUser(){

    // ************************************** Creating seperate dummy objects and arrays(blueprints) for different values (by Pankaj Phour) on january 31 2023 **************************************
    const audience_reach = {name:'Audience Reach',Values:[]}
    const analytics_capture = {name:'Analytics capture',Values:[]}
    const audience_composition = {name:'Audience Composition',Values:[]}
    const monet_custom_report = {name:'Monet Custom Report',Values:[]}
    const advance_reporting_features = {name:'Advance Reporting Features',Values:[]}
    const reporting = {name:'Reporting',Values:[]}
    const survey = {name:'Survey',Values:[]}
    const turnaround = {name:'Turnaround',Values:[]}
    const headers = [audience_reach,analytics_capture,audience_composition,monet_custom_report,advance_reporting_features,reporting,survey,turnaround]

    this._aps.userGet('getNewPlans').subscribe( success => {
      if (success && !success['error']) {
        this.planList = success['response'];
        this.newplanList = [{name: '', price: '', validity: '' , plan_id: '', contact_us: 0, p_type:''}];
        this.planData2.push({ name: 'Audience Reach', values: [[{ name: ['Assets per Year'], tooltip: '' }], [{ name: ['Video Length Limit'], tooltip: '' }], [{ name: ['Maximum Number of Respondents'], tooltip: '' }], [{ name: ['Bring Your Own Panels'], tooltip: '' }], [{ name: ['Monet Panels'], tooltip: '' }]] },
          { name: 'Audience Compositions', values: [[{ name: ['Quotas'] }], [{ name: ['Quota Change'] }], [{ name: ['Country'] }]] },
          // Replaced the Reaction Flexibility with Reaction Emoji Options (by Panakj Phour) on March 09 2023
          { name: 'Analytic Captures', values: [[{ name: ['Emotions'] }], [{ name: ['Reaction Emoji Options'] }], [{ name: ['Eye Trace'] }], [{ name: ['Mouse Hover/Click'] }]] },
          { name: 'Survey', values: [[{ name: ['Default Survey'] }], [{ name: ['Custom Questions'] }], [{ name: ['Extended Features'] }]] },
          { name: 'Turnaround', values: [[{ name: ['Turnaround'] }]] },
          { name: 'Reporting', values: [[{ name: ['Overview Page'] }], [{ name: ['Detailed Page'] }], [{ name: ['Monet Metrics'] }], [{ name: ['Normative Comparisons'] }], [{ name: ['Surveys'] }], [{ name: ['Frame by Frame Emotion Graph'] }], [{ name: ['Frame by Frame Reaction Graph'] }], [{ name: ['Cohorts Analysis'] }]] },
          { name: 'Advanced Reporting Features', values: [[{ name: ['Salient Scenes'] }], [{ name: ['Eye Trace Graph'] }], [{ name: ['Mouse Hover/Click'] }], [{ name: ['Segmentation Analytics of Emotion and Reaction with Surveys'] }], [{ name: ['A/B Comparison Features'] }]] },
          { name: 'Monet Custom Report', values: [[{ name: ['Optional Powerpoint with Salient Scenes, Standard Report'] }], [{ name: ['Additional Creative Insights (custom)'] }]] },
        )
        success['response'].map( (pln,index) => {
          // console.log("All Plan",pln);

          if (!pln.trail) {
            this.newplanList.push({name : pln.p_name, price: pln.p_price, validity: pln.p_validate_plan , plan_id: pln.p_id, contact_us: pln.p_validate_plan,p_text:pln.p_text, p_text_na:pln.p_text_na, p_type:pln.p_type, p_text_price:pln.p_text_price});
          }

          for(let i=0;i<headers.length;i++){
            headers[i].name = headers[i].name.toLowerCase();
            headers[i].name=headers[i].name.replace(" ","_")
            headers[i].name.search(' ') !== -1 ?  headers[i].name=headers[i].name.replace(" ","_") : '';
            headers[i].Values.push(pln[headers[i].name])
          }
          for(let i=0;i<audience_reach.Values[0].length;i++){
            this.planData2[0].values[i].push({name:audience_reach.Values[index][i].v});
            this.planData2[0].values[i][0].tooltip = audience_reach.Values[0][i].i;
          }
          for(let i=0;i<audience_composition.Values[0].length;i++){
            this.planData2[1].values[i].push({name:audience_composition.Values[index][i].v});
            this.planData2[1].values[i][0].tooltip = audience_composition.Values[0][i].i;
          }
          for(let i=0;i<analytics_capture.Values[0].length;i++){
            this.planData2[2].values[i].push({name:analytics_capture.Values[index][i].v});
            this.planData2[2].values[i][0].tooltip = analytics_capture.Values[0][i].i;
          }
          for(let i=0;i<survey.Values[0].length;i++){
            this.planData2[3].values[i].push({name:survey.Values[index][i].v});
            this.planData2[3].values[i][0].tooltip = survey.Values[0][i].i;
          }
          for(let i=0;i<turnaround.Values[0].length;i++){
            this.planData2[4].values[i].push({name:turnaround.Values[index][i].v});
            this.planData2[4].values[i][0].tooltip = turnaround.Values[0][i].i;
          }
          for(let i=0;i<reporting.Values[0].length;i++){
            this.planData2[5].values[i].push({name:reporting.Values[index][i].v});
            this.planData2[5].values[i][0].tooltip = reporting.Values[0][i].i;
          }
          for(let i=0;i<advance_reporting_features.Values[0].length;i++){
            // console.log(advance_reporting_features,index,i,this.planData2)
            this.planData2[6].values[i].push({name:advance_reporting_features.Values[index][i].v});
            this.planData2[6].values[i][0].tooltip = advance_reporting_features.Values[0][i].i;
          }
          for(let i=0;i<monet_custom_report.Values[0].length;i++){
            this.planData2[7].values[i].push({name:monet_custom_report.Values[index][i].v});
            this.planData2[7].values[i][0].tooltip = monet_custom_report.Values[0][i].i;
          }

        });
        this.newplanList.shift();
      }
    });
  }


  sub(){
    this.selected = 1;
  }
  pay(){
this.selected = 0;
  }

  toggle(e:any){
    // console.log(e);
    if(e.checked){
      this.sub()
    }
    else{
      this.pay()
    }
  }


  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! Function to scroll the page and compare between different plan (by Pankaj Phour with Ashwani kumar(back end)) on january 20 2023 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  details(){
    this.comparision = !this.comparision;
    if(this.comparision){
      const a = document.getElementById('compare-button');
      a.classList.add('selected-button');
    }
    else{
      const a = document.getElementById('compare-button');
      a.classList.remove('selected-button');
    }
    setTimeout(() => {
      const b = document.getElementById('comparison');
      b ? b.scrollIntoView({behavior:'smooth'}) : ''
    }, 100);

  }

  Start(e:any){
    // console.log(e);
    if(e<5){
      // localStorage.setItem('startPlan',JSON.stringify(e));
      this.router.navigate(['./auth2.0'],{queryParams:{'register':true,'plan_id':e}})
    }
    else{
      this.router.navigate(['./auth2.0'],{queryParams:{'contact':true}})

    }
  }



}
