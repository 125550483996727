import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared-module';
import { AuthenticationComponent } from './components/authentication/authentication.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import { NotificationComponent } from './components/notification/notification.component';
import { NotifyComponent } from './components/notification/notify/notify.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import {ExtendedModule} from '@angular/flex-layout';
import {MatCardModule, MatIconModule, MatSlideToggleModule, MatTabsModule} from '@angular/material';
import {HelpDialogComponent, TopComponent} from './components/top/top.component';
import {AlertDialogComponent} from './components/alert-dialog/alert-dialog.component';
import { ImagePopupComponent } from './components/image-popup/image-popup.component';
import { HelpMapModule } from './components/help-map/help-map.module';
import {AuthGuard} from './gaurd/auth.guard';
import {IsLoggedIn} from './services/auth.service';
import { IndexPageComponent } from './components/index-page/index-page.component';
import { PlanComponent } from './components/plan/plan.component';

// import { HelpMapComponent } from './components/help-map/help-map.component';
// import { AudienceFormComponent } from './components/audience-form/audience-form.component';
/*import { RegisterAudienceComponent } from './components/register-audience/register-audience.component';*/


@NgModule({
  declarations: [
    AppComponent,
    AuthenticationComponent,
    LoginComponent,
    PageNotFoundComponent,
    RegisterComponent,
    NotificationComponent,
    NotifyComponent,
    ForgotPasswordComponent,
    TopComponent,
    AlertDialogComponent,
    HelpDialogComponent,
    IndexPageComponent,
    PlanComponent,
    // HelpMapComponent,
    // AudienceFormComponent,
   /* RegisterAudienceComponent*/
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    ExtendedModule,
    MatCardModule,
    MatIconModule,
    MatTabsModule,
    HelpMapModule,
    MatSlideToggleModule
  ],
  providers: [AuthGuard, IsLoggedIn],
  bootstrap: [AppComponent],
  entryComponents: [NotifyComponent, AlertDialogComponent, HelpDialogComponent, TopComponent]
})
export class AppModule { }
