import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationComponent } from './components/authentication/authentication.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {HttpInterceptorService} from './module/corporate/services/http-interceptor/http-interceptor.service';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {AuthGuard} from './gaurd/auth.guard';
import {IsLoggedIn} from './services/auth.service';
import { IndexPageComponent } from './components/index-page/index-page.component';
import { PlanComponent } from './components/plan/plan.component';

 const routes: Routes = [
  {
    path: 'corporate',
     loadChildren: () => import('src/app/module/corporate/corporate.module').then(m => m.CorporateModule),
    data: {title: ''},
    canActivate: [AuthGuard]
  },
  {
    path: 'report',
    loadChildren: () => import('src/app/module/reporting/reporting.module').then(m => m.ReportingModule),
    data: {title: ''},
    canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    component: AuthenticationComponent,
    // data: {title: 'Authentication'},
    data: {title: 'Monet'},
    resolve: [IsLoggedIn]
  },
  {
    path: 'forget-password',
    component: ForgotPasswordComponent,
    // data: {title: 'Authentication'},
    data: {title: 'Monet'},
  },
  {
    path: '',
    redirectTo: 'corporate',
    pathMatch: 'full',
  },
  // {
  //   path: 'auth',
  //   redirectTo: 'landingPage',
  //   pathMatch: 'full',
  // },
  {
    path: 'landingPage',
    component : IndexPageComponent,
    data: {title: 'Monet'},
  },
  {
    path: 'plans',
    component : PlanComponent,
    data: {title: 'Monet'},
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    // data: {title: 'Page Not Found'}
    data: {title: 'Monet'}
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterceptorService,
    multi: true
  },
    {provide: LocationStrategy, useClass: HashLocationStrategy}],
})
export class AppRoutingModule { }
