// Some of the code is being commented out in this file.
// If you are working on it then you can check the date of the commented code
// and if it is older than 6 months and the code is working fine then you can go on to delete the commented code
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  // ElementRef,
  Inject,
  // NgZone,
  // Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  //  ActivatedRoute,
   NavigationEnd, Router } from "@angular/router";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ApiService } from "../../module/corporate/services/ApiService/api.service";
import { AuthService } from "../../services/auth.service";
import { AlertDialogComponent } from "../alert-dialog/alert-dialog.component";
import { ConvertTimePipe } from "../../module/reporting/pipes/convert-time.pipe";
// import * as am4core from "@amcharts/amcharts4/core";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { environment } from "../../../environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

export interface DialogData {
  animal: string;
  name: string;
}
@Component({
  selector: "app-top",
  templateUrl: "./top.component.html",
  styleUrls: ["./top.component.scss"],
  providers: [ConvertTimePipe],
})
export class TopComponent implements OnInit, AfterViewInit {
  @ViewChild("menuTrigger",null) trigger;
  cartData: any;
  screen = screen;
  menuShow: any;
  userName: any;
  email: any;
  contact: any;
  campaignname: any;
  avatar: any;
  currentUrl: any = "";
  planData: any = {};
  plan: any;
  maxlimit:any;
  profileInfo: any;
  shortPlan = [];
  tabIndex: any;
  mapData: any;
  user: any;
  env = environment;
  menuData = [
    { name: "Profile", route: "/corporate/diy/profile" },
    { name: "Dashboard", route: "/corporate/diy/campaigns" },
    { name: "Invoice", route: "/corporate/diy/invoice" },
    { name: "Reports", route: "/report" },
  ];
  shortcutKeys: any[] = [
    {
      key: "S",
      work: "Open Survey",
    },
    {
      key: "] [",
      work: "Slide the tab Line/Bar/Ratio",
    },
    {
      key: "E",
      work: "Emotion hide/Un-hide",
    },
    {
      key: "R",
      work: "Reaction hide/Un-hide",
    },
    {
      key: "+ -",
      work: "Manage contrast of video",
    },
    {
      key: "&#9552;",
      work: "Play/Pause the video",
    },
    {
      key: "&rarr;/&larr;",
      work: "Forward/Backward the video",
    },
    {
      key: "&uarr;/&darr;",
      work: "Volume increase/decrease",
    },
    {
      key: "L",
      work: "Live Data",
    },
    {
      key: "M",
      work: "Data Per Minute",
    },
  ];
  locationList = {
    name: "Offices In",
    value: [
      "Barcelona",
      "Chicago",
      "Delhi",
      "Los Angeles",
      "London",
      "Mexico City",
      "Seattle",
      "Dubai",
    ],
  };
  cartVisibility = [
    { type: "create", name: "Create campaign", route: "/corporate/createCam" },
    { type: "config", name: "Configure", route: "/corporate/configure" },
    { type: "config", name: "Configure", route: "/corporate/configure2" },
    { type: "pre", name: "Pre survey", route: "/corporate/preSurvey" },
    { type: "instream", name: "Instream", route: "/corporate/inStream" },
    { type: "post", name: "Post survey", route: "/corporate/postSurvey" },
    {
      type: "rsingle",
      name: "Review single",
      route: "/corporate/review-single",
    },
    {
      type: "rcheckout",
      name: "Review checkout",
      route: "/corporate/review-checkout",
    },
    {
      type: "rcluster",
      name: "Review cluster",
      route: "/corporate/review-cluster",
    },
  ];
  routeIndex = 1;
  /*   bread = [
       {name: 'CreateCampaign', route: 'corporate/create'},
       {name: 'Configure', route: 'corporate/configure'},
       {name: 'PreSurvey', route: 'corporate/preSurvey'},
       {name: 'PostSurvey', route: 'corporate/postSurvey'},
       {name: 'Finish', route: 'corporate/review-checkout'},
    ]; */
  subscription = [
    {
      subscription: "Subscription",
      length: "5 Minutes",
      duration: "10 Days",
      assets: "--",
      max: "100",
    },
  ];
  assetRemaning = [
    {
      name: "Assets Remaining",
      amount: "",
      msg: "*Please upgrade your plan to get more assets",
    },
  ];
  visible: boolean;
  userPLan: any;
  link = false;
  totalVal: any;
  checkRoute = false;
  captcha:any;
  contactForm:FormGroup;
  validCaptcha:boolean = false;
  api_token:any;
  cloneSelected:boolean = false;
  token:any;
  
  constructor(
    private router: Router,
    private dialog: MatDialog,
    public _Api: ApiService,
    private _as: AuthService,
    //  private zone: NgZone,
    public timePipe: ConvertTimePipe,
    private fb:FormBuilder,
    //  private route: ActivatedRoute
  ) {
    this.router.events.subscribe((e) => {
      if (e && e instanceof NavigationEnd) {
        this.visible = e["urlAfterRedirects"] !== "/corporate/index";
        this.checkRoute =
          this.cartVisibility.findIndex((m) => m.route === e["url"]) !== -1;
        if (this.checkRoute) {
          this._as.getCartDetails();
        }
      }
    });
  }
  Validation(){
    this.contactForm = this.fb.group({
        name: ['',Validators.required],
        email: ['',Validators.compose([Validators.required, Validators.email])],
        phone: ['',Validators.required],
        organization: ['',Validators.required],
        message: ['',Validators.required],
        captchaValue: ['',Validators.required],
    })
  }
  openDialog(): void {
    //  const dialogRef =
     this.dialog.open(HelpDialogComponent, {
      width: "800px",
    });
    //  dialogRef.afterClosed().subscribe((result) => {});
  }
  routeProfile() {
    this.trigger.closeMenu();
    this.router.navigateByUrl("corporate/profile");
  }

  routePlans(){
    this.routePlan();
    this.trigger.closeMenu();
    this.router.navigateByUrl("corporate/plans")
  }
    // Added this function to save the page location details in the localStorage (by Pankaj Phour) on March 14 2023
    routePlan(){
      const hash = window.location.href.indexOf('#')
        const link = window.location.href.substring(hash+1);
        // console.log(link);
        localStorage.setItem('previous_link', link);
    }
  logout() {
    const data = {
      head: "Confirmation",
      msg: "Do you want to logout?",
      yes: "Logout",
      no: "Cancel",
    };
    const dialogref = this.dialog.open(AlertDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      data: data,
      width: "350px",
      height: "250px",
      panelClass: "deleteCampaigns",
    });
    dialogref.afterClosed().subscribe((next) => {
      if (next === "yes") {
        this._Api.userGet("logout").subscribe((success) => {
          if (success) {
            this.api_token = false;
            localStorage.clear();
            sessionStorage.clear();
            this.visible = false;
            this.router.navigate(["../../landingPage"]);
            this._as.obNotify({
              status: "success",
              message: "Logged out",
              start: true,
              code: 200,
            });
          }
        });
      }
    });
    this.trigger.closeMenu();
  }
  getMain() {
    this.campaignname = localStorage.getItem("cmp_name");
    this.currentUrl = this.router.url;
    /*  if (!this.plan) {
          this.plan = {};
          this.getPlanDetails();
        } */
  }
  getCartInfo() {
    this._as.cart$.subscribe((cart) => {
      if (cart && cart.contents) {
        this.cartData = cart;
        this.cartData["number"] = cart.contents.reduce(
          (a, c) => c.charges.length + a,
          0
        );
      }
    });
  }
  removeFromCart(val) {
    this._Api
      .userPost("removeFromCart", {
        cnt_id: localStorage.getItem("cnt_map_id"),
        toc_id: val.toc_id,
      })
      .subscribe((res) => {
        if (res && res["response"]) {
          this._as.obCart(res["response"]);
        }
      });
  }
  /* googleTranslateElementInit() {
     new google.translate.TranslateElement({
       pageLanguage: 'en',
       includedLanguages: 'ar,zh-CN,en,pl,ja,bn,fr,de,hi,it'
     }, 'google_translate_element');
  } */
  ngOnInit() {
    this.token = localStorage.getItem('CSRF-TOKEN');
    this._Api.campaignSelectedforCloneEvent.subscribe((event:any)=>{
      if(event===true){
        this.cloneSelected = true
      }
      else{
        this.cloneSelected = false;
      }
    })


    this._as.planUpgradeEvent.subscribe((data:any)=>{
      if(data){
        this.getUser();
      }
    })
    this._as.logOutEvent.subscribe((data:any)=>{
      if(data){
        this.api_token = false;
      }
    })
    this._as.logInEvent.subscribe((data:any)=>{
      if(data){
        this.api_token = true;
       setTimeout(() => {
        
         this.getUser();
       }, 2000);
      }
    })
    this.api_token = localStorage.getItem("api_token");
    this.Validation()
    /* this.googleTranslateElementInit();*/
   this.api_token ?  this.getUser() : '';
    // this.getUser();
    this._as.profile$.subscribe((next) => {
      if (next) {
        // console.log(next);

        this.user = next;
      }
    });
    this.getCartInfo();
    //    this._Api.user$.subscribe((user) => {
    // //  setTimeout( () => {
    //    this.userName = user.name;
    //    this.email = user.email;
    //    this.contact = user.contact;
    //    this.avatar = user.avatar;
    // //  }, 1500);
    //    });
    this.getMain();
    this.router.events.subscribe((next) => {
      if (this.router.url.search("auth") !== -1) {
        this.plan = undefined;
      } else {
        this.getMain();
      }
    });

    this._as.planChange.subscribe(x=>{
      if(x){

       this.api_token ?  this.getUser() : '';
      //  this.getUser();
      }
    })

  }
  ngAfterViewInit(): void {}
  tabClick(tab) {
    // console.log("Hello from conatct us",tab);
    //  tslint:disable-next-line:no-unused-expression triple-equals
    tab.index == 2 ? (this.mapData = 2) : "";
    this.tabIndex = tab.index;
    if(tab.index==1){

      this.captchaCreate()
    }
    if (this.tabIndex === 3 || this.tabIndex === 4) {
      this.link = true;
    } else {
      this.link = false;
    }
  }

  captchaCreate(){
    this.contactForm.get('captchaValue').reset()
    const length = 5;
    const values = '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
    let captcha = ''
    for(let i=0;i<length;i++){
       captcha += values[Math.floor(Math.random()*values.length-1)]
      }
      // for(let i=0;i<captcha.length;i++){
      //   console.log("Captcha value",Math.floor(Math.random()*values.length-1),captcha)
        if(captcha.includes(undefined)){
          captcha = captcha.replace('undefined','#')
        // }
    }
    // console.log(this.captcha)
    this.captcha = captcha;
  }

  captchaChecker(e:any){
    const value =  e.target.value;
    // console.log(value,this.captcha)
    if(value==this.captcha){
      // console.log("Valid captcha");
      this.validCaptcha = true;
    }
    else{
      this.validCaptcha = false;
    }
  }
  getUser(): void {
    this._as.userGet("getUser").subscribe((success:any) => {
      // console.log("Calling getUser api");
      
      if (success && !success["error"]) {
        // console.log("getuser by top");
        this._as.profile(success["response"]);
        this.profileInfo = success["response"];
        //  console.log(this.profileInfo, "Info..");
        if (success["response"] && success["response"].plan) {
          this.userPLan = success["response"].plan;
          this.userName = success["response"].comp_name;
          this.email = success["response"].email;
          this.contact = success["response"].contact;
          this.avatar = success["response"].avatar;
          this._as.plan.next(success['response'].plan.p_type);
          this._as.quotaCheck.next(success['response'].plan.p_add_quota);
          this._as.maxlimit.next(success['response'].plan.p_quota_size_limit);
          this._as.settings.next(success['response'].settings)
          localStorage.setItem('planName',success['response'].plan.p_name);

          if(success.response.pending_tasks){
            // this._as.pendingPayment(true)
          }
          //  console.log("Plan",success);

          this.shortPlan = [
            { name: "Your Plan", value: success["response"].plan.p_name },
            {
              name: "Content Length Limit",
              value:
                success["response"].plan.p_max_video_length / 60 + " minutes",
            },
            //  {name: 'Duration of Test', value: ''},
            {
              // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! Changed the number of total assets to the remaining assets of the user's account ( by Pankaj Phour) on january 20 2023 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
              // name: "Number of Assets",
              name: "Remaining Assets",
              value: success["plan_usage"][1].remaining,
            },
            {
              name: "Max Respondents",
              value: success["response"].plan.p_quota_size_limit.max,
            },
          ];
          // console.log(this.shortPlan);
          
          this._as.getUserPLanOb(success["response"].plan);
          this._as.getUserPLanAllData(success["response"]);
        }
        this._as.showTutorials(!success["response"].has_cmp);
      }
    });
  }

  Submit(){
    const data = {
      qr_name:this.contactForm.value.name,
      qr_phone:this.contactForm.value.phone,
      qr_organization:this.contactForm.value.organization,
      qr_text:this.contactForm.value.message,
      qr_email:this.contactForm.value.email
    }
    this._as.supportPost(data,'query').subscribe((item:any)=>{
      // console.log(item);
      this.contactForm.reset();
      this._as.obNotify({
        status:'success',
        code:200,
        message:'Query submitted successfully',
        start:true
      })
    })
  }
  cancel(){
    this.contactForm.reset();
  }

  signIn(){
    this.router.navigate(['./auth'])
  }
  signUp(){
    this.router.navigate(['./auth'],{queryParams: {'register':true}})
  }

  goToDashboard(){
    let url = window.location.href;
    
    if(url.indexOf('clone') !== -1 && !this.cloneSelected){
      const data = {
        head: "Confirmation",
        msg: "The clone configuration step isn't completed yet. Are you sure you want to leave this page ?",
        yes: "Yes, leave this page.",
        no: "Stay, on the page.",
        route : 'dashboard'
      };
    let a =  this.dialog.open(AlertDialogComponent,{
        disableClose: true,
      hasBackdrop: true,
      data: data,
      width: "400px",
      height: "280px",
      panelClass: "deleteCampaigns",
      })
      a.afterClosed().subscribe((next:any)=>{
        if(next==='yes'){
          this.router.navigate(['./corporate/dashboard'])
        }
      })
    }
    else{
      this.router.navigate(['./corporate/dashboard'])
    }
  }
  goToIndex(){
    let url = window.location.href;
    if(url.indexOf('clone') !== -1 && !this.cloneSelected){
      const data = {
        head: "Confirmation",
        msg: "The clone configuration step isn't completed yet. Are you sure you want to leave this page ?",
        yes: "Yes, leave this page.",
        no: "Stay, on the page.",
        route : 'index'
      };
    let a =  this.dialog.open(AlertDialogComponent,{
        disableClose: true,
      hasBackdrop: true,
      data: data,
      width: "400px",
      height: "280px",
      panelClass: "deleteCampaigns",
      })
      a.afterClosed().subscribe((next:any)=>{
        if(next==='yes'){
          this.router.navigate(['./corporate/index'])
        }
      })
    }
    else{
      this.router.navigate(['./corporate/index'])
    }
  }
}
@Component({
  selector: "app-help-dialog",
  templateUrl: "helpDialog.html",
  styleUrls: ["./top.component.scss"],
})
export class HelpDialogComponent implements AfterViewInit {
  showTutorial: any;
  env = environment;
  constructor(
    public dialogRef: MatDialogRef<HelpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _as: AuthService,
    private _cdr: ChangeDetectorRef
  ) {
    //  this.showTutorial = localStorage.getItem('tutorial');
  }
  shortcutKeys: any[] = [
    {
      key: "S",
      work: "Open Survey",
    },
    {
      key: "] [",
      work: "Slide the tab Line/Bar/Ratio",
    },
    {
      key: "E",
      work: "Emotion hide/Un-hide",
    },
    {
      key: "R",
      work: "Reaction hide/Un-hide",
    },
    {
      key: "+ -",
      work: "Manage contrast of video",
    },
    {
      key: "&#9552;",
      work: "Play/Pause the video",
    },
    {
      key: "&rarr;/&larr;",
      work: "Forward/Backward the video",
    },
    {
      key: "&uarr;/&darr;",
      work: "Volume increase/decrease",
    },
    {
      key: "L",
      work: "Live Data",
    },
    {
      key: "M",
      work: "Data Per Minute",
    },
  ];
  onNoClick(): void {
    this.dialogRef.close();
  }
  setTutorial(e) {
    this._as.showTutorials(e.checked);
    this.showTutorial = e.checked;
    // //  console.log(!e.checked);
    //  localStorage.setItem('has_cmp', (e.checked).toString());
  }
  ngAfterViewInit(): void {
    this._as._showTutorials$.subscribe((tut) => (this.showTutorial = tut));
    this._cdr.detectChanges();
  }

 
}
