import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'convertTime',

})
export class ConvertTimePipe implements PipeTransform {

  transform(value: number): string {
    if (value > 3600) {
      const hours   = Math.floor(value / 3600);
      const minutes = Math.floor((value - (hours * 3600)) / 60);
      const seconds = value - (hours * 3600) - (minutes * 60);
      return Math.floor(hours) < 10 ? '0' + Math.floor(hours) + 'h ' + (Math.floor(minutes) < 10 ? '0' + Math.floor(minutes) + ':' + ( seconds < 10 ? '0' + seconds : seconds) + '' : Math.floor(minutes) + ':' + ( seconds < 10 ? '0' + seconds : seconds) + '') : Math.floor(hours) + 'h ' + (Math.floor(minutes) < 10 ? '0' + Math.floor(minutes) + ':' + ( seconds < 10 ? '0' + seconds : seconds) + '' : Math.floor(minutes) + ':' + ( seconds < 10 ? '0' + seconds : seconds) + '');
    } else {
      const minutes: number = Math.floor(value / 60);
      return Math.floor(minutes) < 10 ? '0' + Math.floor(minutes) + ':' + ( Math.floor(value - minutes * 60) < 10 ? '0' + Math.floor(value - minutes * 60) + '' : Math.floor(value - minutes * 60)) : Math.floor(minutes) + ':' + ( Math.floor(value - minutes * 60) < 10 ? '0' + Math.floor(value - minutes * 60)  + '' : Math.floor(value - minutes * 60) + '') + '';
    }
  }
  second(value) {
    if (Number(value) > 3600) {
      const a = String(value).split(':');
      return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
      } else {
      const a = String(value).split(':');
      return (+a[0]) * 60 + (+a[1]);
    }
  }
}
