// Some of the code is being commented out in this file.
// If you are working on it then you can check the date of the commented code
// and if it is older than 6 months and the code is working fine then you can go on to delete the commented code
import { Component, ElementRef, Input, OnInit, Output, EventEmitter, ViewChild } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Route, Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { ApiService } from "../../module/corporate/services/ApiService/api.service";
import { environment } from "../../../environments/environment";

// *********************************  Changed the regex format from 2,3  to 2,5 in the end (by Pankaj Phour) on february 13 2023  *********************************
const EMAIL_REGEX = /^[a-zA-Z]+[a-zA-Z0-9._]+@[a-zA-Z]+\.[a-zA-Z.]{2,5}$/;
const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[_!@#$%^&*()+=;:'",<.>/?~`-])[^ ]{8,}$/;

/* function passwordMatcher(c:  AbstractControl) {
   return c.get('password').value === c.get('password_confirmation').value
     ? null :  { 'nomatch':  true };
} */
function passwordMatcher(c: AbstractControl) {
  return c.get("password").value === c.get("password_confirmation").value
    ? null
    : { nomatch: "password not matching" };
}
@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  @Output() changeStage = new EventEmitter<any>();
 @Input() getForget;
 @Input() ResetPassword;
  forgetPassword: FormGroup;
  otpForm: FormGroup;
  passwords: FormGroup;
  tabIndex = 0;
  activation = false;
  chide = true;
  hide = true;
  loading = false;
  reset = false;
  env = environment;
  token:any;
  @ViewChild("otpEle0",null) otpEle: ElementRef;
  @ViewChild("otpEle1",null) otpEle1: ElementRef;
  @ViewChild("otpEle2",null) otpEle2: ElementRef;
  @ViewChild("otpEle3",null) otpEle3: ElementRef;
  otp = false;
  constructor(
    private fb: FormBuilder,
    private _aps: ApiService,
    private _as: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  otpInput = [
    { control: "one", name: "otpEle" },
    { control: "two", name: "otpEle1" },
    { control: "three", name: "otpEle2" },
    { control: "four", name: "otpEle3" },
  ];

  createForm(): FormGroup {
    return this.fb.group({
      company_email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(EMAIL_REGEX),
        ]),
      ],
      otp: [Math.random()],
    });
  }
  keyPressed(e) {
    // console.log(e);
  }
  createOtpForm() {
    this.otpForm = this.fb.group({
      one: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(1)]),
      ],
      two: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(1)]),
      ],
      three: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(1)]),
      ],
      four: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(1)]),
      ],
    });
    this.passwords = this.fb.group(
      {
        password: [
          "",
          Validators.compose([
            Validators.required,
            Validators.pattern(PASSWORD_REGEX),
          ]),
        ],
        password_confirmation: [
          "",
          Validators.compose([
            Validators.required,
            Validators.pattern(PASSWORD_REGEX),
          ]),
        ],
      },
      { validator: passwordMatcher }
    );
    this.passwords.valueChanges.subscribe(() => {});
  }

  selectInput(which) {
    if (which === 1) {
      this.otpEle.nativeElement.select();
    }
    if (which === 2) {
      this.otpEle1.nativeElement.select();
    }
    if (which === 3) {
      this.otpEle2.nativeElement.select();
    }
    if (which === 4) {
      this.otpEle3.nativeElement.select();
    }
  }
  keytab(e, event) {
    if (e.key !== "Backspace") {
      if (event === 1) {
        this.otpEle1.nativeElement.select();
        this.otpEle1.nativeElement.focus();
      } else if (event === 2) {
        this.otpEle2.nativeElement.select();
        this.otpEle2.nativeElement.focus();
      } else if (event === 3) {
        this.otpEle3.nativeElement.select();
        this.otpEle3.nativeElement.focus();
      }
    }
  }

  clearOtp(i) {
    setTimeout(() => {
      if (i === 1) {
        this.otpEle.nativeElement.focus();
      } else if (i === 2) {
        this.otpEle1.nativeElement.focus();
      } else if (i === 3) {
        this.otpEle2.nativeElement.focus();
      }
    });
  }



  ngOnInit() {
    this.token = localStorage.getItem('CSRF-TOKEN');
    this.createOtpForm();
    this.forgetPassword = this.createForm();
      if (this.getForget) {
        if (Object.keys(this.getForget).length > 0) {
          if (this.getForget.token&& this.getForget.company_email) {
            // console.log('static')
            sessionStorage.setItem("token", this.getForget.token);
            sessionStorage.setItem("email", this.getForget.company_email);
            this.forgetPassword
              .get("company_email")
              .setValue(this.getForget.company_email);
            this.tabIndex = 3;
          }
        }
      };
    /* if (sessionStorage.getItem('otp') && sessionStorage.getItem('token')) {
       this.tabIndex = 3;
    } */
    if(this.ResetPassword){
      this.tabIndex = 3;
    }
  }

  sendEmail() {
    if (this.forgetPassword.get("company_email").invalid) {
      this.forgetPassword.get("company_email").markAsTouched();
      return;
    }
    if (!this.loading && this.forgetPassword.valid) {
      this.loading = true;
      this._aps
        .userPost("forgotPassword", {
          company_email: this.forgetPassword.get("company_email").value
            ? this.forgetPassword.get("company_email").value.toLowerCase()
            : sessionStorage.getItem("email"),
            verification_link: window.location.origin + '/v2.2/'
        })
        .subscribe((data) => {
          if (data["error"] === false) {
            this.loading = false;
            sessionStorage.setItem("otp", data["response"].otp);
            sessionStorage.setItem("token", data["response"].token);
            sessionStorage.setItem(
              "email",
              this.forgetPassword.get("company_email").value
            );
            this.changeStage.emit(true)
              this._as.obNotify({
              status: "info",
              message: data["message"],
              start: true,
              code: data["status"],
            });
          } else {
            this.loading = false;
            this._as.obNotify({
              status: "error",
              message: data["message"],
              start: true,
              code: data["status"],
            });
          }
        });
    }
  }

  //  verifyOTP() {
  //    if (!this.loading) {
  //      this.loading = true;
  //      let otp = sessionStorage.getItem("otp");
  //      if (this.otpForm.get("one").value) {
  //        otp =
  //          this.otpForm.get("one").value +
  //          this.otpForm.get("two").value +
  //          this.otpForm.get("three").value +
  //          this.otpForm.get("four").value;
  //      }
  //      sessionStorage.setItem("otp", otp);
  //      // this.tabIndex = 3;
  //      this._aps
  //        .userPost("verifyOTP", {
  //          otp: otp,
  //          company_email: sessionStorage.getItem("email"),
  //        })
  //        .subscribe((success) => {
  //          this.loading = false;
  //          // console.log('1', success);
  //          if (success["error"] === true) {
  //            this._as.obNotify({
  //              status: "error",
  //              message: success["message"],
  //              start: true,
  //              code: success["status"],
  //            });
  //          } else {
  //            // console.log('else', otp);
  //            sessionStorage.setItem("otp", otp);
  //            this.tabIndex = 3;
  //          }
  //        });
  //    }
  //  }
  // changeurl(){
  //   this.router.navigate(['./auth2.0']);
  // }
  resetPassword() {
    this._aps
      .userPost("resetPassword", {
        otp: sessionStorage.getItem("otp"),
        token: sessionStorage.getItem("token"),
        company_email: sessionStorage.getItem("email"),
        password: this.passwords.get("password").value,
      })
      .subscribe((data) => {
        this.loading = false;
        if (data["error"] === false) {
          // sessionStorage.setItem('otp', data['response'].otp);
          // sessionStorage.setItem('token', data['response'].token);
          // this.tabIndex = 4;
          this._as.obNotify({
            status: "info",
            message: data["message"],
            start: true,
            code: data["status"],
          });
          this.clearStorage()
          this.changeStage.emit(true);
        } else {
          this._as.obNotify({
            status: "error",
            message: data["message"],
            start: true,
            code: data["status"],
          });
          // this.tabIndex = 2;
        }
      });
  }
  clearStorage() {
    sessionStorage.clear();
    localStorage.clear();
  }
}
