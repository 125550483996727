// Some of the code is being commented out in this file.
// If you are working on it then you can check the date of the commented code
// and if it is older than 6 months and the code is working fine then you can go on to delete the commented code


import {Component, DoCheck, ElementRef, HostListener, OnInit, ViewChild,
  // enableProdMode, HostListener,
  } from '@angular/core';
  import {ActivatedRouteSnapshot, NavigationEnd, NavigationStart, Event, Router, NavigationCancel, NavigationError, ActivatedRoute, RouterOutlet, RoutesRecognized} from '@angular/router';
  import {Title} from '@angular/platform-browser';
  declare var MediaRecorder: any;
  declare var google: any;
  // for demo
  import { Injectable } from '@angular/core';
  // import {Location} from '@angular/common';
// import {ImageCroppedEvent} from 'ngx-image-cropper';
// import html2canvas from 'html2canvas';
// import {AuthService} from './services/auth.service';
// import { HttpClient } from '@angular/common/http';
// import { forkJoin } from 'rxjs';
// import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
/*import * as moment from 'moment';*/
import {environment} from '../environments/environment';  // RxJS 6 syntax
import { HttpClient } from '@angular/common/http';
@Injectable()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('video',null) videoElementRef: ElementRef;
  isTop = false;
  get videoElement(): HTMLVideoElement {
    return this.videoElementRef.nativeElement;
  }
  visible = false;
  env = environment;
  stream: any;
  googleTranslate = false;
  smallScreen: any = false;
  width:any;
  height:any;
  mobile:boolean = false;
  tablet:boolean = false;
  data:any;

  constructor(
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute,
    private http:HttpClient
  ) {
    this.router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.loader = true;
      }
      if ( routerEvent instanceof NavigationEnd || routerEvent instanceof NavigationCancel || routerEvent instanceof NavigationError) {
        this.loader = false;
      }
      if ( routerEvent instanceof NavigationEnd || routerEvent instanceof NavigationCancel || routerEvent instanceof NavigationError) {
        const notop = [ '/auth', '/corporate/inactivity/token_not_provided'];
        /* this.visible = routerEvent['urlAfterRedirects'] !== '/corporate/index' && routerEvent['urlAfterRedirects'] !== '/auth' && routerEvent['urlAfterRedirects'] !=='/corporate/inactivity/token_not_provided';*/
        this.visible = !notop.includes(routerEvent['urlAfterRedirects']);
      }
      /*if (routerEvent && routerEvent instanceof NavigationEnd) {
        console.log(routerEvent['urlAfterRedirects']);
        this.visible = routerEvent['urlAfterRedirects'] !== '/corporate/index' && routerEvent['urlAfterRedirects'] !== '/auth' && routerEvent['urlAfterRedirects'] !=='/corporate/inactivity/token_not_provided';
      }*/
    });
    this.http.get(environment.apiUrl + 'csrf-token').subscribe((data:any)=>{
      if(data.error === false){
        this.data = true;
        localStorage.setItem('CSRF-TOKEN',JSON.stringify(data.response.csrf_token))
      }
      else{
        this.data = false;
      }
    })
  }
  loader = true;


  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! Code to detect a screen size to block page on small screens (by Pankaj Phour) on january 27 2023 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  @HostListener('window:resize', ['$event'])
  onResize(e: any) {
    // console.log(e,'yryryryryrry yryry ryryr yyy');
    
    // Updated the innerheight from 720 to 640 to make the webpage available on smaller screens ( instructed by saurav sir)
    if (e.target.innerWidth < 1280 || e.target.innerHeight < 640) {
      this.smallScreen = true;
    } else {
      this.smallScreen = false;
    }
  }

  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! Function for generating the title of the page according to the routes and the components loaded (by Pankaj Phour) on janaury 27 2023 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  generateTitle(snapshot: ActivatedRouteSnapshot) {
    let titleParts = <string[]>[];
    if (snapshot) {
      if (snapshot.firstChild) {
        titleParts = titleParts.concat(this.generateTitle(snapshot.firstChild));
      }
      if (snapshot.data['title']) {
        titleParts.push(snapshot.data['title']);
        titleParts.reverse();
      }
    }
    return titleParts;
  }
  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  download() {
    let shouldStop = false;
    let stopped = false;
    const downloadLink = document.getElementById('download');
    const stopButton = document.getElementById('stop');
    stopButton.addEventListener('click', function() {
      shouldStop = true;
    });
    const options = {mimeType: 'video/webm'};
    const recordedChunks = [];
    const mediaRecorder = new MediaRecorder(this.stream, options);

    mediaRecorder.addEventListener('dataavailable', function(e) {
      if (e.data.size > 0) {
        recordedChunks.push(e.data);
      }
      if (shouldStop === true && stopped === false) {
        mediaRecorder.stop();
        stopped = true;
      }
    });
    mediaRecorder.addEventListener('stop', function() {
      downloadLink['href'] = URL.createObjectURL(new Blob(recordedChunks));
      downloadLink['download'] = 'acetest.webm';
    });
    mediaRecorder.start();
  }

  async startRecording() {
    const self = this;
    this.stream = await navigator.mediaDevices['getDisplayMedia'](
      { video: true });
    // console.log(this.stream);
    this.videoElement.srcObject = this.stream;
  }
  addClass(el: HTMLCollection) {
    for (let i = 0; i < el.length; i++) {
      el[i].classList.add('notranslate');
      el[i].setAttribute('translate', 'no');
      if (el[i].children) {
        this.addClass(el[i].children);
      }
    }
  }
  googleTranslateElementInit() {
    // tslint:disable-next-line:no-unused-expression
    new google.translate.TranslateElement({
      pageLanguage: 'en',
      includedLanguages: 'ar,zh-CN,en,pl,ja,bn,fr,de,hi,it'
    }, 'google_translate_element');
  }
  ngOnInit () {
    // console.log("Build after 3:30 on 29 june");

    this.width = window.innerWidth;
    this.height = window.innerHeight;

    // console.log(navigator,navigator.userAgent.match(/Android/i),window.orientation);
     if(navigator.userAgent.match(/Android/i)
     || navigator.userAgent.match(/webOS/i)
     || navigator.userAgent.match(/iPhone/i)
     || navigator.userAgent.match(/BlackBerry/i)
     || navigator.userAgent.match(/Windows Phone/i)
     || navigator.userAgent.match(/Mobile/i) && ! navigator.userAgent.match(/iPad/i) || window.innerWidth < 481){
      this.mobile = true;
      this.tablet = false;
     }
     else if(navigator.userAgent.match(/iPad/i)
     || navigator.userAgent.match(/iPod/i)
     || ((window.innerHeight < window.innerWidth) && ((navigator.userAgent.match(/Android/i))
     || (navigator.userAgent.match(/Mobile/i)))) || window.innerWidth < 769){
      this.tablet = true;
      this.mobile = false;
     }
     else{
      this.smallScreen = false;
      this.mobile = false;
      this.tablet = false;
     }
    this.route.queryParams.subscribe( params => {
      if (params && params.token) {
        this.isTop = false;
      } else {
        this.isTop = true;
      }
    });
    this.router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        const r = event.state.root.firstChild['_routerState'].url;
        if (r === '/corporate/preSurvey') {
             localStorage.setItem('surveyType', 'pre');
           }
          if (r === '/corporate/inStream') {
            localStorage.setItem('surveyType', 'inStream');
          }
        if (r === '/corporate/postSurvey') {
          localStorage.setItem('surveyType', 'post');
        }
      }
      if (event instanceof NavigationEnd) {
        this.loader = false;
        this.titleService.setTitle(this.generateTitle(this.router.routerState.snapshot.root).join(' | '));
      }
    });

    // Updated the innerheight from 720 to 640 to make the webpage available on smaller screens ( instructed by saurav sir)
    if (window.innerWidth < 1280 || window.innerHeight < 640) {
      this.smallScreen = true;
    } else {
      this.smallScreen = false;
    }

  }

}




// Another function to get more accurate results (here ua = navigator.userAgent)
// if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
//   return "tablet";
// }
// else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
//   return "mobile";
// }
