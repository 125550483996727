import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private as: AuthService, private route: Router){

  }
  canActivate(): boolean {
    // console.log("Hello from canActivate function");
    
    if(this.as.loggedIn()) {
      return true;
    } else {
      // console.log("Routing to auth page");
      
      // this.route.navigate(['/auth']);
      this.route.navigate(['/landingPage']);
      return false;
    }
  }
}
