// Some of the code is being commented out in this file.
// If you are working on it then you can check the date of the commented code
// and if it is older than 6 months and the code is working fine then you can go on to delete the commented code
import {Component, ComponentFactoryResolver, Inject, OnInit, Type, ViewChild, ViewContainerRef} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {
  // @ts-ignore
  @ViewChild('container', { read: ViewContainerRef, static: Boolean }) container;
  components = [];
  form: FormGroup;
  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private resolver: ComponentFactoryResolver,
              public dialogRef: MatDialogRef<AlertDialogComponent>,
              private _fb: FormBuilder) { }
  addComponent(componentClass: Type<any>, config) {
    const componentFactory = this.resolver.resolveComponentFactory(componentClass);
    const component = this.container.createComponent(componentFactory);
    component.instance.campaignData = config;
    this.components.push(component);
  }

  ngOnInit() {
    if (this.data.component) {
      //  console.logthis.data, 'data compo');
      this.addComponent(this.data.component, this.data.data);
    }
    if (this.data.camp) {
      this.form = this._fb.group({
        cmp_name: new FormControl(this.data.camp.cmp_name, Validators.required),
        cmp_description: new FormControl(this.data.camp.cmp_description, Validators.required),
        cmp_id: new FormControl(localStorage.getItem('cmp_id'))
      });
    }
  }

}
