// Some of the code is being commented out in this file. 
// If you are working on it then you can check the date of the commented code 
// and if it is older than 6 months and the code is working fine then you can go on to delete the commented code 
import {Component, OnInit,
  //  OnChanges,
    Input, NgZone, AfterViewInit} from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
//  import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4maps from "@amcharts/amcharts4/maps"; 
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {environment} from '../../../environments/environment';
am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-help-map',
  templateUrl: './help-map.component.html',
  styleUrls: ['./help-map.component.scss']
})
export class HelpMapComponent implements OnInit, AfterViewInit {

  constructor(private zone: NgZone) { }
  @Input() mapData : any;
  env = environment;
  loading = false;
  ngOnInit() {

  }
  ngAfterViewInit(): void {
    this.loading = true;
    setTimeout(() => {
      this.generateMap();
    }, 1000);
  }
 /*  ngOnChanges() {
     this.mapData == 2
     ? this.generateMap() : '';
  } */
  
  generateMap() {
      this.loading = false;
    /* this.zone.runOutsideAngular(()=>{*/

      am4core.useTheme(am4themes_animated);
      //  Themes end
      //  Create map instance
      let chart = am4core.create("helpmap", am4maps.MapChart);
      
      //  Set map definition
      chart.geodata = am4geodata_worldLow;
      
      //  Set projection
      chart.projection = new am4maps.projections.Miller();
      
      //  Create map polygon series
      let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
      
      //  Exclude Antartica
      polygonSeries.exclude = ["AQ"];
      
      //  Make map load polygon (like country names) data from GeoJSON
      polygonSeries.useGeodata = true;
      
      //  Configure series
      let polygonTemplate = polygonSeries.mapPolygons.template;
      polygonTemplate.tooltipText = "{name}";
      polygonTemplate.polygon.fillOpacity = 0.6;
      
      
      //  Create hover state and set alternative fill color
      let hs = polygonTemplate.states.create("hover");
      hs.properties.fill = chart.colors.getIndex(0);
      
      //  Add image series
      let imageSeries = chart.series.push(new am4maps.MapImageSeries());
      imageSeries.mapImages.template.propertyFields.longitude = "longitude";
      imageSeries.mapImages.template.propertyFields.latitude = "latitude";
      imageSeries.mapImages.template.tooltipText = "{title}";
      imageSeries.mapImages.template.propertyFields.url = "url";
      
      let circle = imageSeries.mapImages.template.createChild(am4core.Circle);
      circle.radius = 3;
      circle.propertyFields.fill = "color";

      let circle2 = imageSeries.mapImages.template.createChild(am4core.Circle);
      circle2.radius = 2;
      circle2.propertyFields.fill = "color";

      setTimeout(() => {
        imageSeries.dataItems.values.map( (p, i) => {
          if(p.dataContext['title'] === 'California - Head Office') {
            imageSeries.mapImages.template.children.values[0].clones.values[i]['radius'] = 8;
            imageSeries.mapImages.template.children.values[1].clones.values[i]['radius'] = 8;
          }
        });
      }, 2000);
      circle2.events.on("inited", function(event){
        animateBullet(event.target);
      });
      
      
      function animateBullet(circle) {
          let animation = circle.animate([{ property: "scale", from: 1, to: 5 }, { property: "opacity", from: 1, to: 0 }], 1000, am4core.ease.circleOut);
          animation.events.on("animationended", function(event){
            animateBullet(event.target.object);
          })
      }
      
      let colorSet = new am4core.ColorSet();
      
      imageSeries.data = [ {
        "title": "Barcelona - Sub Office",
        "latitude": 41.38879,
        "longitude": 2.15899,
        "color":colorSet.next()
      },
        {
        "title": "Chicago - Sub Office",
        "latitude":	41.881832,
        "longitude": -87.623177,
        "color":colorSet.next()
      }, {
        "title": "Gurgaon - Development Center",
        "latitude": 28.457523,
        "longitude": 	77.026344,
        "color":colorSet.next()
      }, 
      {
        "title": "London - Sub Office",
        "latitude": 51.509865,
        "longitude": -0.118092,
        "color":colorSet.next()
      },/* {
         "title": "Los Angeles - Sub Office",
         "latitude": 34.052235,
         "longitude": -118.243683,
         "color":colorSet.next()
      } , */
        {
          'title': 'California - Head Office',
          'latitude': 34.052235,
          'longitude':-118.243683,
          color: colorSet.next()
        },
      {
        "title": "Mexico City - Sub Office",
        "latitude":	19.432608,
        "longitude": 	-99.133209,
        "color":colorSet.next()
      },
      {
        "title": "Seattle - Sub Office",
        "latitude":	47.608013,
        "longitude": -122.335167,
        "color":colorSet.next()
        
      },
      {
        "title": "Dubai - Sub Office",
        "latitude":		25.276987,
        "longitude": 	55.296249,
        "color":colorSet.next()
      }, ];
     

    /* }) */
     
  }

}
