// Some of the code is being commented out in this file. 
// If you are working on it then you can check the date of the commented code 
// and if it is older than 6 months and the code is working fine then you can go on to delete the commented code 
import { Component
  //  , OnInit 
} from '@angular/core';
import { environment } from 'src/environments/environment';
//  import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent
//   implements OnInit 
 {

  env = environment;
  //  constructor() { }

  //  ngOnInit() {
  //  }

}
