// Some of the code is being commented out in this file.
// If you are working on it then you can check the date of the commented code
// and if it is older than 6 months and the code is working fine then you can go on to delete the commented code
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoginComponent } from "../login/login.component";
import { RegisterComponent } from "../register/register.component";
import { environment } from "../../../environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "src/app/services/auth.service";
/* import html2canvas from 'html2canvas';
import {ImageCroppedEvent} from 'ngx-image-cropper'; */

@Component({
  selector: "app-authentication",
  templateUrl: "./authentication.component.html",
  styleUrls: ["./authentication.component.scss"],
})
export class AuthenticationComponent implements OnInit {
  tabIndex: any = true;
  top = false;
  env = environment;
  contactForm:FormGroup;
  captcha:any;
  validCaptcha:boolean = false;
  contact:boolean = false;
  ResetPassword:boolean = false;
  tabs: any = [
    { name: "Login", url: "/auth/login", component: LoginComponent },
    { name: "Sign Up", url: "/auth/register", component: RegisterComponent },
  ];
  forgetData: any;

  constructor(private router: Router ,private route:ActivatedRoute, private fb:FormBuilder, private _as:AuthService) {}
  tabChange(e) {
    // console.log(e);
    this.tabIndex = e;
  }
  pageroute(e) {
    this.router.navigate([this.tabs[e.index].url]);
  }
  Validation(){
    this.contactForm = this.fb.group({
        name: ['',Validators.required],
        email: ['',Validators.compose([Validators.required, Validators.email])],
        phone: ['',Validators.required],
        organization: ['',Validators.required],
        message: ['',Validators.required],
        captchaValue: ['',Validators.required],
    })
  }

  ngOnInit() {
    this._as.contactValueEvent.subscribe((data:any)=>{
      // console.log(data)
      this.contact = data;
    })
    this.Validation();
    this.captchaCreate()
    this.route.queryParams.subscribe((res:any) =>{
      console.log(res)
      this.forgetData=res;
      if(res.redirect == 'forget'){
        this.contact = false;
        this.tabIndex = true;
        this.ResetPassword = true;
      }
     else if(res.register){
        this.tabIndex = false
        this.ResetPassword = false;
      }
      else if(res.contact){
        this.contact = true;
        this.tabIndex = true;
        this.ResetPassword = false;
      }
      else{
        this.tabIndex = true;
        this.contact = false;
        this.ResetPassword = false;
      }
    })

    //  if (this.router.url.search("forget") !== -1) {
    //    this.top = true;
    //  } else {
    //    this.top = false;
    //  }
    //  if (this.router.url.search("register") !== -1) {
    //    this.tabIndex = 1;
    //  } else if (this.router.url.search("login") !== -1) {
    //    this.tabIndex = 0;
    //  }
    //  this.router.events.subscribe((url) => {
    //    if (this.router.url.search("forget") !== -1) {
    //      this.top = true;
    //    } else {
    //      this.top = false;
    //    }
    //    if (this.router.url.search("register") !== -1) {
    //      this.tabIndex = 1;
    //    } else if (this.router.url.search("login") !== -1) {
    //      this.tabIndex = 0;
    //    }
    //  });
  }



  captchaCreate(){
    this.contactForm.get('captchaValue').reset()
    const length = 5;
    const values = '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
    let captcha = ''
    for(let i=0;i<length;i++){
       captcha += values[Math.floor(Math.random()*values.length-1)]
      }
      // for(let i=0;i<captcha.length;i++){
      //   console.log("Captcha value",Math.floor(Math.random()*values.length-1),captcha)
        if(captcha.includes(undefined)){
          captcha = captcha.replace('undefined','#')
        // }
    }
    // console.log(this.captcha)
    this.captcha = captcha;
  }

  captchaChecker(e:any){
    const value =  e.target.value;
    // console.log(value,this.captcha)
    if(value==this.captcha){
      // console.log("Valid captcha");
      this.validCaptcha = true;
    }
    else{
      this.validCaptcha = false;
    }
  }

  submit(){
    const data = {
      qr_name:this.contactForm.value.name,
      qr_phone:this.contactForm.value.phone,
      qr_organization:this.contactForm.value.organization,
      qr_text:this.contactForm.value.message,
      qr_email:this.contactForm.value.email
    }
    this._as.supportPost(data,'query').subscribe((data:any)=>{
      // console.log(data);
      this.contactForm.reset();
      this._as.obNotify({
        status:'success',
        code:200,
        message:'Query submitted successfully',
        start:true
      })
      setTimeout(() => {
        this.contact = false;
      }, 2000);
    })
  }
  cancel(){
    this.contactForm.reset();
    this.contact = false;
  }

  changeTab(){
    this.tabIndex = !this.tabIndex;
    // this.contact = false;
    if(this.tabIndex){
      this.contact = false;
    }
  }
  // Added this function on mouse over/enter on cancel button just for fun ( By Pankaj Phour) You can ignore this function and it's code unless you want to learn from it
  // move(e:any){
  //   console.log(e);
  //   const button = document.getElementById('cancelButton');
  //   button.style.position = 'absolute'
  //   button.style.left = (Math.random()*100).toString() + '%'
  //   button.style.top = (Math.random()*100).toString() + '%'

  // }


  updateResetPassword(e:any){

    this.ResetPassword = false;
    console.log("Checking value of reset password",this.ResetPassword,e);
    this.router.navigate([],{queryParams:{token:null, company_email:null,redirect:null},queryParamsHandling:'merge'});
    this.forgetData = undefined;

  }


}
