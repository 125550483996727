import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from "@angular/core";
import { AuthService } from "../services/auth.service";

@Directive({
  selector: "[appTooltipSmall]",
})
export class TooltipSmallDirective {
  @Input() tooltipData;
  delay = 50;
  tooltip: HTMLElement;
  offset = 10;
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private _as: AuthService
  ) {
    _as.walkthrough({ el });
  }
  @HostListener("mouseenter") onMouseEnter() {
    if (!this.tooltip) {
      this.show();
    }
  }

  @HostListener("mouseleave") onMouseLeave() {
    if (this.tooltip && !this.tooltipData.yes) {
      this.hide();
    }
  }

  show() {
    this.create();
    this.setPosition();
    this.renderer.addClass(this.tooltip, "ng-tooltip-show");
  }

  hide() {
    this.renderer.removeClass(this.tooltip, "ng-tooltip-show");
    window.setTimeout(() => {
      this.renderer.removeChild(document.body, this.tooltip);
      this.tooltip = null;
    }, this.delay);
  }
  create() {
    this.tooltip = this.renderer.createElement("div");
    // const title = this.renderer.createElement('div');
    // this.renderer.appendChild(title, this.renderer.createText(this.tooltipData.title));
    const desc = this.renderer.createElement("div");
    this.renderer.appendChild(
      desc,
      this.renderer.createText(this.tooltipData.description)
    );
    //  this.renderer.addClass(title, 'titleTooltip');
    this.renderer.addClass(desc, "descTooltips");
    // this.renderer.appendChild(this.tooltip, title);
    this.renderer.appendChild(this.tooltip, desc);
    this.renderer.appendChild(document.body, this.tooltip);
    // this.renderer.appendChild(this.el.nativeElement, this.tooltip);
    this.renderer.addClass(this.tooltip, `ng-tooltips`);
    if (
      this.tooltipData.description === "scare" ||
      this.tooltipData.description === "angry" ||
      this.tooltipData.description === "disgust" ||
      this.tooltipData.description === "surprise" ||
      this.tooltipData.description === "sad" ||
      this.tooltipData.description === "happy"
    ) {
      this.renderer.addClass(
        this.tooltip,
        `ng-tooltips-${this.tooltipData.description}`
      );
      this.renderer.addClass(
        this.tooltip,
        `ng-tooltip-${this.tooltipData.position}s-${this.tooltipData.description}`
      );
    }
    this.renderer.addClass(this.tooltip, "tooltip-custom");
    this.renderer.addClass(
      this.tooltip,
      `ng-tooltip-${this.tooltipData.position}s`
    );
    this.renderer.setStyle(
      this.tooltip,
      "-webkit-transition",
      `opacity ${this.delay}ms`
    );
    this.renderer.setStyle(
      this.tooltip,
      "-moz-transition",
      `opacity ${this.delay}ms`
    );
    this.renderer.setStyle(
      this.tooltip,
      "-o-transition",
      `opacity ${this.delay}ms`
    );
    this.renderer.setStyle(
      this.tooltip,
      "transition",
      `opacity ${this.delay}ms`
    );
    if (this.tooltipData.yes) {
      this.createYes();
    }
  }

  createYes() {
    const button = this.renderer.createElement("button");
    this.renderer.addClass(button, "tooltipButton");
    this.renderer.appendChild(
      button,
      this.renderer.createText(this.tooltipData.yes)
    );
    this.renderer.appendChild(this.tooltip, button);
    this.renderer.listen(button, "click", () => {
      // console.log(this.el);
      this.hide();
    });
  }
  setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect();
    const tooltipPos = this.tooltip.getBoundingClientRect();
    const scrollPos =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    let top, left;
    if (this.tooltipData.position === "top") {
      top = hostPos.top - tooltipPos.height - this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }
    if (this.tooltipData.position === "bottom") {
      top = hostPos.bottom + this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }
    if (this.tooltipData.position === "left") {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this.offset;
    }
    if (this.tooltipData.position === "right") {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this.offset;
    }
    this.renderer.setStyle(this.tooltip, "top", `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltip, "left", `${left}px`);
  }
}
