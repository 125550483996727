import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {environment} from '../../../environments/environment';
// import { EventEmitter } from 'protractor';

// *********************************  Changed the regex format from 2,3 to 2,5 in the end (by Pankaj Phour) on february 13 2023  *********************************
const EMAIL_REGEX = /^[a-zA-Z]+[a-zA-Z0-9._]+@[a-zA-Z]+\.[a-zA-Z.]{2,5}$/;
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[_!@#$%^&*()+=;:'",<.>/?~`-])[^ ]{8,}$/;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loading = false;
  not_verified = false;
  hide = true;
  loginform: FormGroup;
  forgotPs = false;
  error: string;
  env = environment;
  invalid = false;
  // contact = true;
  contactform:FormGroup;
  captcha:any;
  validCaptcha:boolean = false;
  successMessage:boolean = false;
  @Input() getForget;
  @Input() contact;
  @Input() ResetPassword;


  @Output() updateResetPassword = new EventEmitter();
  forgetData: any;
  constructor(
    private fb: FormBuilder,
    private _as: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.createForm();
  }
  login() {
    this.loading = true;
    this.loginform.value.company_email = this.loginform.value.company_email.toLowerCase();
    this._as.userPost(this.loginform.value, 'login').subscribe(
      (success) => {
        if (success['error'] === false) {
          this._as.logIn(true)
          this._as.obNotify({status: 'success', message: success['message'], start: true, code: Number(success['code'])});
          localStorage.setItem('email', success['response'].email);
          localStorage.setItem('has_cmp', success['response'].has_cmp);
          this._as.profile(success['response']);
          localStorage.setItem('api_token', success['response'].token);
          localStorage.setItem('comp_admin', success['response'].comp_admin);
          localStorage.setItem('avatar', success['response'].avatar);
          localStorage.setItem('comp_id', success['response'].comp_id);
          this._as.plan.next(success['response'].plan.p_name)
          //  localStorage.setItem('contact_no', success['response'].comp_contact);
      /*     localStorage.setItem('can_del_asset', success['response'].plan.p_settings.can_del_asset);
           localStorage.setItem('can_del_campaign', success['response'].plan.p_settings.can_del_campaign);
           localStorage.setItem('can_skip_def_survey', success['response'].plan.p_settings.can_skip_def_survey);
           localStorage.setItem('random', success['response'].plan.p_settings.can_rand_ques_options);
           localStorage.setItem('can_instream', success['response'].plan.p_settings.enable_in_stream);*/
          //  localStorage.setItem('tutorial', success['response'].has_cmp === 'true' ? 'false' : 'true');
          if (success['plan_remaining'] === 0) { /* || success['response']['assets'] === 0*/
            this._as.obNotify({status: 'warn', message: '0 assets remaining', start: true, code: Number(success['code'])});
            //  this.router.navigate(['corporate/diy/plans']);
            this.router.navigate(['corporate/profile']);
            //  this.router.navigate(['corporate/dashboard']);
            /* this._as.userPut({plan_id: success['response'].plan_id}, 'updateProfile').subscribe( scc => {/!*success['response'].plan_id*!/
               if (scc && !scc['error']) {
                 localStorage.setItem('plan_id', scc['plan_id']);
                 if (success['response'].plan_id) {
                   // localStorage.setItem('plan_id', success['response'].plan_id);
                   this.router.navigate(['corporate/diy/campaigns']);
                 } else {
                   this.router.navigate(['corporate/diy/plans']);has_cmp
                 }
               }
            }); */
          } else {
            if (success['response'].plan_id) {
              localStorage.setItem('plan_id', success['response'].plan_id);
              //  this.router.navigate(['corporate/diy/campaigns']);
              //  this.router.navigate(['corporate/index']);
              this.router.navigate([success['response'].has_cmp ? 'corporate/dashboard' : 'corporate/index' ]);
            } else {
              //  this.router.navigate(['corporate/diy/plans']);
              //  this._as.obNotify({status: 'warn', message: 'Plan expired', start: true, code: Number(success['code'])});
              //  this.router.navigate(['corporate/index']);
              this.router.navigate([success['response'].has_cmp ? 'corporate/dashboard' : 'corporate/index' ]);
            }
          }
        } else if (success['error'] === true || success['code'] === 400 || success['code'] === 404 || success['code'] === 403 || success['code'] === 409 || success['code'] === 412) {
          this.loading = false;
          if (!success['response'].verified_email && success['message'].search('verified') !== -1) {
            this.not_verified = true;
            this._as.obNotify({status: 'error', message: 'Please Verify Your Account To Continue.', start: true, code: Number(success['code'])});
          } else {
            this._as.obNotify({status: 'error', message: success['message'], start: true, code: Number(success['code'])});
          }
        } else if (success['error'] === true || success['code'] === 400 || success['code'] === 404 || success['code'] === 403 || success['code'] === 409 || success['code'] === 412) {
          //  this.loading = false;
          this._as.obNotify({status: 'error', message: success['message'], start: true, code: Number(success['code'])});
        }
      },
      () => {}
    );
  }

cancelbtn(){
  this.contact = false;
}

  contactValidation(){
    this.contactform = this.fb.group({
      qr_name: ['',Validators.required],
      qr_email: ['',Validators.compose([Validators.required,Validators.email])],
      qr_message:['',Validators.compose([Validators.required,Validators.maxLength(200)])],
      captchaValue:['',Validators.required],

    })
  }

  createForm() {
    this.loginform = this.fb.group({
      company_email: ['', Validators.compose([Validators.required, Validators.pattern(EMAIL_REGEX)])],
      company_password: ['', Validators.compose([Validators.required, Validators.pattern(PASSWORD_REGEX)])]
    });
  }
  mailLogin(params) {
    this._as.userPost(params, 'emailVerification').subscribe( success => {
      if (success['code'] === 200 && success['error'] === false) {
        this.invalid = false;
        if (success['response'].company_status === 0) {
          this._as.obNotify({status: 'info', message: 'Please Verify Your Account To Continue.', start: true, code: Number(success['code'])});
          this.not_verified = true;
        } else {
          this._as.obNotify({status: 'success', message: success['message'], start: true, code: Number(success['code'])});
          localStorage.setItem('email', success['response'].email);
          localStorage.setItem('api_token', success['response'].token);
          localStorage.setItem('comp_admin', success['response'].comp_admin);
          localStorage.setItem('comp_id', success['response'].comp_id);
          localStorage.setItem('avatar', success['response'].avatar);
          //  localStorage.setItem('contact_no', success['response'].contact);
          this.router.navigate(['corporate/index']);
        }
      } else if (success['error'] === true || success['code'] === 400 || success['code'] === 404 || success['code'] === 403 || success['code'] === 409 || success['code'] === 412) {
        this.not_verified = true;
        this.loading = false;
        this.invalid = true;
        this._as.obNotify({status: 'error', message: success['message'], start: true, code: Number(success['code'])});
      }
    });
  }

  resendEmail(): void {
    this._as.userPost({company_email: this.loginform.get('company_email').value}, 'emailResend').subscribe((res) => {
      if (!res['error']) {
        this._as.obNotify({status: 'success', message: res['message'], start: true, code: +res['code']});
      } else {
        this._as.obNotify({status: 'error', message: res['message'], start: true, code: +res['code']});
      }
    });
  }

  ngOnInit(): void {
    this.contactValidation();
    this.captchaCreate();
    // console.log(this.getForget)
    /* sessionStorage.clear();
    localStorage.clear(); */
    if(this.getForget.redirect === 'forget'){
      this.forgetData = this.getForget
      this.forgotPs = true
    }else{
      this.route.queryParams.subscribe((params:any) => {
        if (params && params.token) {
          if (Object.keys(params).length > 0) {
             this.mailLogin(params);
          }
        }
        if(params.contact){
          this.contact = true;
        }
      });
    }

  }



  captchaCreate(){
    this.contactform.get('captchaValue').reset()
    const length = 5;
    const values = '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
    let captcha = ''
    for(let i=0;i<length;i++){
       captcha += values[Math.floor(Math.random()*values.length-1)]
      }
      // for(let i=0;i<captcha.length;i++){
      //   console.log("Captcha value",Math.floor(Math.random()*values.length-1),captcha)
        if(captcha.includes(undefined)){
          captcha = captcha.replace('undefined','#')
        // }
    }
    // console.log(this.captcha)
    this.captcha = captcha;
  }

  captchaChecker(e:any){
    const value =  e.target.value;
    // console.log(value,this.captcha)
    if(value==this.captcha){
      // console.log("Valid captcha");
      this.validCaptcha = true;
    }
    else{
      this.validCaptcha = false;
    }
  }

  submit(){
    this.successMessage = true;
    this.contactform.reset();
    setTimeout(() => {
      this.successMessage = false;
      // console.log("Emitting value")
      this._as.contactF(false)
    }, 3000);
  }

updatePassword(e:any){

  this.ResetPassword = false;
  this.forgotPs = false;
  console.log("Setting value of resetPass",this.ResetPassword,e);
  this.forgetData = undefined;
  this.updateResetPassword.emit(false);
}
}
