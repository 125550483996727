import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HttpClientXsrfModule} from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TooltipDirective } from './directives/tooltip.directive';
import { TooltipSmallDirective } from './directives/tooltip-small.directive';
import {
  MatAutocompleteModule, MatBadgeModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule, MAT_DIALOG_DATA, MatDialogRef,
  MatDividerModule, MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatExpansionModule,
  MatStepperModule,
  MatTreeModule, MatNativeDateModule, MatSidenavModule
} from '@angular/material';
import {FlexLayoutModule} from '@angular/flex-layout';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatToolbarModule,
    MatTabsModule,
    FlexLayoutModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatStepperModule,
    MatSelectModule,
    MatTreeModule,
    MatExpansionModule,
    MatChipsModule,
    MatSidenavModule,
    MatAutocompleteModule,
    MatIconModule,
    ReactiveFormsModule,
    MatListModule,
    MatTooltipModule,
    MatGridListModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDividerModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSliderModule,
    // MatRippleModule,
    MatBadgeModule,
    MatSnackBarModule,
    MatTableModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    HttpClientXsrfModule,
    FormsModule,
    RouterModule,
    MatTabsModule,
    MatTooltipModule,
    MatToolbarModule,
    MatBadgeModule,
    FlexLayoutModule,
    MatCardModule,
    MatDividerModule,
    MatStepperModule,
    // MatRippleModule,
    MatInputModule,
    MatButtonModule,
    MatSidenavModule,
    MatExpansionModule,
    MatSliderModule,
    MatSelectModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
    ReactiveFormsModule,
    MatListModule,
    MatGridListModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatSnackBarModule,
    MatTableModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    TooltipDirective,
    TooltipSmallDirective
    
  ],
  declarations: [TooltipDirective, TooltipSmallDirective],
  entryComponents: [],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }
  ]
})
export class SharedModule { }
