import { NgModule } from '@angular/core';
import { HelpMapComponent } from './help-map.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports : [
        CommonModule,
        ReactiveFormsModule
    ],
    exports : [
        HelpMapComponent
    ],
    declarations : [
        HelpMapComponent
    ]
})

export class HelpMapModule {}